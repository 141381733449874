import { AvatarInfo, ProfileInfoDto } from '../types/ProfileInfo';
import { ProfileStatistic } from '../types/ProfileStatistic';

export type OnlineStatus = 'online' | 'away' | 'offline';

export type Role = 'Support Agent' | 'Support Manager';

export type RoleType = 'ROLE_MANAGER' | 'ROLE_SUPPORT' | 'ROLE_ADMIN';

export type Roles = Record<RoleType, Role>;

export type ProfileUpdateValues = {
  firstName: string;
  lastName: string;
  newPassword: string | null;
  email: string;
  avatar: AvatarInfo | null;
  emailNotification: boolean;
  assignNotification: boolean;
  status: OnlineStatus;
  showGuide: boolean;
  showAutomationGuideModal: boolean;
};

export type UpdatableValue = string | boolean | null | undefined;

export class Profile {
  role: Role;
  id: number;
  username: string;
  email: string;
  firstName: string;
  online: boolean;
  lastName: string;
  avatar: AvatarInfo | undefined;
  uid: string;
  statistic: ProfileStatistic | undefined;
  enabled = true;
  shouldChangePassword = false;
  companyName: string | undefined;
  emailNotification: boolean;
  assignNotification: boolean;
  away: boolean;
  status: OnlineStatus;
  newPassword: string | null = null;
  showGuide = false;
  show_automation_guide_modal: boolean;

  constructor(profileDto: ProfileInfoDto) {
    this.role =
      !!profileDto.roles && profileDto.roles.includes('ROLE_MANAGER')
        ? 'Support Manager'
        : 'Support Agent';
    this.avatar = profileDto.avatar;
    this.uid = profileDto.uid;
    this.firstName = profileDto.first_name;
    this.lastName = profileDto.last_name;
    this.email = profileDto.email;
    this.username = profileDto.username;
    this.id = profileDto.id;
    this.online = profileDto.online;
    this.statistic = profileDto.statistic
      ? {
          assignedChat: profileDto.statistic.processedTotal,
          closedChat: profileDto.statistic.closedTotal,
          online: profileDto.statistic.totalOnline,
        }
      : {
          assignedChat: 0,
          closedChat: 0,
          online: 0,
        };
    this.enabled = profileDto.enabled;
    this.shouldChangePassword = profileDto.must_change_password;
    this.companyName = profileDto.merchant?.name;
    this.emailNotification = profileDto.email_notification || false;
    this.assignNotification = profileDto.assign_notification || false;
    this.away = profileDto.away;
    this.status = profileDto.status;
    this.showGuide = profileDto.show_guide;
    this.show_automation_guide_modal = profileDto.show_automation_guide_modal;
  }

  public static emptyProfile(): Profile {
    return new Profile({
      email: '',
      enabled: false,
      first_name: '',
      id: 0,
      last_login: '',
      last_name: '',
      online: false,
      roles: ['ROLE_SUPPORT'],
      uid: '',
      username: '',
      must_change_password: false,
      subscription: null,
      email_notification: false,
      assign_notification: false,
      away: false,
      status: 'online',
      show_guide: false,
      show_automation_guide_modal: true,
    });
  }

  public updatableValues(): ProfileUpdateValues {
    return {
      firstName: this.firstName,
      lastName: this.lastName,
      newPassword: this.newPassword,
      email: this.email,
      avatar: this.avatar || null,
      emailNotification: this.emailNotification,
      assignNotification: this.assignNotification,
      status: this.status,
      showGuide: this.showGuide,
      showAutomationGuideModal: this.show_automation_guide_modal,
    };
  }

  public update(next: ProfileUpdateValues): void {
    if (this.isNotEmptyAndSame(this.firstName, next.firstName)) {
      this.firstName = next.firstName;
    }
    if (this.isNotEmptyAndSame(this.lastName, next.lastName)) {
      this.lastName = next.lastName;
    }
    if (this.isNotEmptyAndSame(this.newPassword, next.newPassword)) {
      this.newPassword = next.newPassword;
    }
    if (this.isNotEmptyAndSame(this.email, next.email)) {
      this.email = next.email;
    }
    this.avatar = next.avatar || undefined;
    this.emailNotification = next.emailNotification;
    this.assignNotification = next.assignNotification;
  }

  public changePassword(password: string): void {
    this.newPassword = password;
  }

  public updateGuidStatus(status: boolean): void {
    this.showGuide = status;
  }

  public hasVisitedAutomationsPage(visited: boolean): void {
    this.show_automation_guide_modal = visited;
  }

  private isNotEmptyAndSame(
    current: UpdatableValue,
    next: UpdatableValue,
  ): boolean {
    return !!next && current !== next;
  }
}
