import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { SlugsService } from '../../../../../@core/services/automations/slugs.service';
import { WidgetService } from '../../../../../@core/services/automations/widget.service';
import { ProductsDetails } from '../../../../../@core/types/Automations/Widgets/ProductsDetails';
import {
  Widget,
  WidgetType,
} from '../../../../../@core/types/Automations/Widgets/Widget';
import { ProductDetails } from '../../../../../@core/types/ProductDetails';
import { requiredFieldsValidator } from '../../../../../@core/validators/requiredFields';
import { validateLinkUrl } from '../../../../../@core/validators/linkValidator';

@Component({
  selector: 'norby-product-widget',
  templateUrl: './product-widget.component.html',
  styleUrls: ['./product-widget.component.css'],
})
export class ProductWidgetComponent implements OnInit, OnChanges {
  public editLinkBox = false;
  public editImageBox = false;
  @ViewChild('contentBox', { read: ElementRef }) private contentBox:
    | ElementRef
    | undefined;
  @Input() widgetDetails: Widget | undefined;
  @Input() widgetType: WidgetType = 'product';
  @Output() save = new EventEmitter();
  @Output() remove = new EventEmitter();
  @Output() update = new EventEmitter();

  productForm: UntypedFormGroup;
  index: number | null = null;

  constructor(
    private fb: UntypedFormBuilder,
    protected ws: WidgetService,
    protected ss: SlugsService,
  ) {
    this.productForm = this.fb.group({
      product_list: this.fb.array([]),
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widgetDetails?.currentValue?.widget) {
      if (changes.widgetDetails.currentValue.type === 'product') {
        this.setInitialProductValue({
          ...changes.widgetDetails.currentValue.widget,
        });
      } else if (changes.widgetDetails.currentValue.type === 'product_list') {
        this.setInitialProductsValues(
          changes.widgetDetails.currentValue.widget,
        );
      }
    }
  }

  ngOnInit(): void {
    if (!this.widgetDetails) {
      this.addProductForm();
    }
  }

  saveProducts(): void {
    this.checkButtonFieldsValidity();
    if (this.productForm.valid) {
      const productData = this.setProductData(this.productForm.getRawValue());
      if (this.widgetDetails?.id) {
        this.update.emit(productData);
      } else {
        this.save.emit(productData);
      }
      this.ss.savingWidget = false;
    }
  }

  checkButtonFieldsValidity(): boolean {
    let valid = true;
    this.formArrayProductsInformation.controls.forEach(
      (control: AbstractControl) => {
        const group = control as UntypedFormGroup;
        const buttonGroup = group.get('button') as UntypedFormGroup;

        const nameControl = buttonGroup?.get('name');
        const linkControl = buttonGroup?.get('link');

        if (
          (nameControl?.value && !linkControl?.value) ||
          (!nameControl?.value && linkControl?.value)
        ) {
          valid = false;
          group.setErrors({ buttonFields: true });
        }
      },
    );
    return valid;
  }

  public get formArrayProductsInformation(): UntypedFormArray {
    return this.productForm.get('product_list') as UntypedFormArray;
  }

  addProductForm(): void {
    this.ss.savingWidget = false;
    this.formArrayProductsInformation.push(
      this.fb.group(
        {
          img: [''],
          price: [''],
          title: [''],
          description: [''],
          button: this.fb.group({
            name: [''],
            link: [''],
          }),
        },
        {
          validators: requiredFieldsValidator(
            'img',
            'title',
            'button.name',
            'button.link',
          ),
        },
      ),
    );
    this.scrollRight();
  }

  scrollRight(): void {
    setTimeout(() => {
      this.contentBox?.nativeElement.scrollTo({
        left: this.contentBox?.nativeElement.scrollLeft + 360,
        behavior: 'smooth',
      });
    }, 0);
  }

  setProductData(products: ProductsDetails): ProductDetails | ProductDetails[] {
    const productsList = this.addHttpsTransferProtocolToButtonLink(
      products.product_list,
    );
    if (products.product_list.length === 1) {
      return productsList[0];
    } else {
      return productsList;
    }
  }

  addHttpsTransferProtocolToButtonLink(
    products: ProductDetails[],
  ): ProductDetails[] {
    const newProductList = [];
    for (const product of products) {
      if (product.button) {
        const { link, name } = product.button;
        const modifiedObject = {
          ...product,
          button: { name, link: validateLinkUrl(link) },
        };
        newProductList.push(modifiedObject);
      }
    }
    return newProductList;
  }

  editButtonLink(i: number): void {
    this.editLinkBox = true;
    this.index = i;
  }

  editImageLink(i: number): void {
    this.editImageBox = true;
    this.index = i;
  }

  // Initial values for the products list
  setInitialProductsValues(data: ProductDetails[] | ProductDetails): void {
    if (Array.isArray(data)) {
      const productControls: UntypedFormGroup[] = [];
      data.forEach(({ title, description, price, button, img }) => {
        productControls.push(
          this.fb.group({
            img,
            price,
            title,
            description,
            button: this.fb.group({
              name: button?.name,
              link: button?.link,
            }),
          }),
        );
      });
      this.formArrayProductsInformation.controls = productControls;
    } else {
      this.setInitialProductValue(data);
    }
  }

  // Initial values for the product
  setInitialProductValue(data: ProductDetails): void {
    const { title, description, price, button, img } = data;
    const productControl: UntypedFormGroup[] = [];
    productControl.push(
      this.fb.group({
        img,
        price,
        title,
        description,
        button: this.fb.group({
          name: button?.name,
          link: button?.link,
        }),
      }),
    );
    this.formArrayProductsInformation.controls = productControl;
  }

  closeBox(): void {
    this.index = null;
    if (this.editImageBox || this.editLinkBox) {
      this.editImageBox = false;
      this.editLinkBox = false;
    }
  }

  closeModalWindow(): void {
    if (this.ss.savingWidget) {
      this.ss.savingWidget = false;
    }
  }

  removeProduct(i: number): void {
    this.formArrayProductsInformation.removeAt(i);
    const productData = this.setProductData(this.productForm.getRawValue());
    if (this.formArrayProductsInformation.length === 0) {
      this.remove.emit();
      this.ws.reset();
    } else if (this.widgetDetails) {
      if (this.widgetDetails?.id) {
        this.update.emit(productData);
      } else {
        this.save.emit(productData);
      }
    }
    this.ss.savingWidget = false;
  }

  removeAll(): void {
    this.remove.emit();
  }
}
