<ng-container>
  <norby-form-widget-container
    [isMandatory]="isMandatory"
    [isVisible]="true"
    (toggleMandatory)="toggleForm()"
  >
    <div slot="content">
      <div class="input-inner">
        <div class="required-flex" [ngStyle]="{ 'margin-right': '16px' }">
          <input
            [formControl]="calendarWidgetFormLabel"
            [ngClass]="{
              'button-input': true,
              'input-description': true,
              'input-description--error':
                calendarWidgetFormLabel.invalid &&
                calendarWidgetFormLabel.touched,
            }"
            type="text"
            placeholder="Enter a label …"
          />
          <small *ngIf="isMandatory" class="required-asterisk">*</small>
          <div
            *ngIf="
              calendarWidgetFormLabel.invalid && calendarWidgetFormLabel.touched
            "
            class="error-message"
          >
            Calendar label is required.
          </div>
        </div>
        <div class="form-input-wrapper" *ngIf="showCalendar">
          <ngx-flatpickr-wrapper
            class="button-input custom-datepicker"
            [config]="datePickerConfig"
            [placeholder]="'Calendar preview'"
          ></ngx-flatpickr-wrapper>
        </div>
        <norby-checkbox-button
          [checked]="isRange"
          (checkedChange)="toggleDateRange()"
        >
          <p class="checkbox__label">Set date range (range of dates)</p>
        </norby-checkbox-button>

        <norby-checkbox-button
          [checked]="isTime"
          (checkedChange)="toggleTimeRange()"
        >
          <p class="checkbox__label">Set time (if needed date with time)</p>
        </norby-checkbox-button>

        <norby-checkbox-button
          [checked]="isMultiply"
          (checkedChange)="toggleMultiplyRange()"
        >
          <p class="checkbox__label">
            Set multiple dates (can select multiple dates)
          </p>
        </norby-checkbox-button>

        <norby-full-attributes-select
          [currentValue]="attributeValue"
          (selectChange)="onSelectChange($event)"
        />
      </div>
    </div>
  </norby-form-widget-container>
</ng-container>
