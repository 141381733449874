<div class="form-container">
  <div *ngFor="let widget of formDataInput" class="form-group">
    <ng-container *ngIf="widget.type === 'Checkbox'">
      <div class="form-control-group">
        <label class="form-label">{{ widget.data.label }}</label>
        <ul class="form-checkbox-list">
          <li *ngFor="let option of widget.data.options">
            <input
              type="checkbox"
              [id]="option.key"
              [checked]="option.isChecked"
              disabled
              class="form-checkbox"
            />
            <label [for]="option.key" class="form-checkbox-label">{{
              option.value
            }}</label>
          </li>
        </ul>
      </div>
    </ng-container>

    <ng-container *ngIf="widget.type === 'Radio'">
      <div class="form-control-group">
        <label class="form-label">{{ widget.data.label }}</label>
        <ul class="form-radio-list">
          <li *ngFor="let option of widget.data.options">
            <input
              type="radio"
              [id]="option.key"
              [checked]="option.isChecked"
              disabled
              class="form-radio"
            />
            <label [for]="option.key" class="form-radio-label">{{
              option.value
            }}</label>
          </li>
        </ul>
      </div>
    </ng-container>

    <ng-container *ngIf="widget.type === 'Select'">
      <div class="form-control-group">
        <label class="form-label">{{ widget.data.label }}</label>
        <select disabled class="form-select">
          <option *ngFor="let option of widget.data.options">
            {{ option.value }}
          </option>
        </select>
      </div>
    </ng-container>

    <ng-container *ngIf="widget.type === 'Input'">
      <div class="form-control-group">
        <label class="form-label">{{ widget.data.description }}</label>
        <input
          type="text"
          [value]="''"
          [placeholder]="widget.data.inputs.placeholder"
          readonly
          class="form-input"
        />
      </div>
    </ng-container>
    <ng-container *ngIf="widget.type === 'Calendar'">
      <div class="form-control-group">
        <label class="form-label">{{ widget.data.label }}</label>
        <input
          type="text"
          [value]="widget.data.attribute"
          [placeholder]="widget.data.attribute"
          readonly
          class="form-input"
        />
      </div>
    </ng-container>
  </div>
</div>
