import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, map, Subject, takeUntil } from 'rxjs';
import { Slug } from '../../../@core/domain/Slug';
import { PaymentService } from '../../../@core/services/automations/payment.service';
import { SlugsService } from '../../../@core/services/automations/slugs.service';
import { MixpanelService } from '../../../@core/services/mixpanel.service';
import { SpinnerService } from '../../../@core/services/spinner.service';
import { ProfileService } from '../../../@core/services/profile.service';
import { UploadWidgetService } from '../../../@core/services/automations/upload-widget.service';
import { AuthService } from '../../../@core/services/auth.service';
import { NotificationsService } from '../../../@core/services/notifications.service';

@Component({
  selector: 'norby-slugs-sidebar',
  templateUrl: './slugs-sidebar.component.html',
  styleUrls: ['./slugs-sidebar.component.css'],
})
export class SlugsSidebarComponent implements OnInit, OnDestroy {
  currentSlug: Slug | undefined;
  isLoadingBots = true;
  isMobile = false;
  merchantApiKey: string | null = null;

  private widgetInitialized: boolean = false;
  private unsubscribe$: Subject<void> = new Subject<void>();

  @HostListener('window:resize') onResize() {
    window.innerWidth <= 600 || window.innerHeight <= 440
      ? (this.isMobile = true)
      : (this.isMobile = false);
  }

  constructor(
    public spinnerService: SpinnerService,
    protected ss: SlugsService,
    protected ps: PaymentService,
    protected prs: ProfileService,
    private mx: MixpanelService,
    private router: Router,
    private uploadWidgetService: UploadWidgetService,
    private authService: AuthService,
    public ns: NotificationsService,
  ) {}

  ngOnInit(): void {
    this.authService.isAuthenticated
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isAuthenticated) => {
        if (isAuthenticated) {
          this.merchantApiKey = localStorage.getItem('ma');
          if (!this.widgetInitialized) {
            this.uploadWidgetService.initializeChatWidget(this.merchantApiKey);
            this.widgetInitialized = true;
          }
        }
      });
    this.ss.resetCounter();
    this.ss.getBotsList().subscribe(() => {
      this.isLoadingBots = false;
      if (this.ss.filteredType$.value === 'all') {
        this.initBotsList(Number(this.ss.botOptions[0].key));
      } else {
        this.ss.loadFilteredSlugs({
          botId: this.ss.selectedBotId$.value,
          type: this.ss.filteredType$.value,
          slug: null,
        });
      }
    });
    this.ss.selectedSlug$.next(undefined);
  }

  readonly showSpinner = combineLatest([
    this.ss.spinnerSidebar$,
    this.ps.hasNotIncompleteAndEndedPayments,
    this.ps.hasIncompleteAndEndedPayments,
    this.ps.hasNotPaymentEnded,
  ]).pipe(
    map(
      ([
        showSpinner,
        hasNotIncompleteAndEndedPayments,
        hasIncompleteAndEndedPayments,
        hasNotPaymentEnded,
      ]) =>
        showSpinner &&
        (hasNotPaymentEnded ||
          hasIncompleteAndEndedPayments ||
          hasNotIncompleteAndEndedPayments),
    ),
  );

  readonly showActiveSlugsTab = combineLatest([
    this.ss.currentTab$,
    this.ps.hasNotIncompleteAndEndedPayments,
    this.ps.hasIncompleteAndEndedPayments,
    this.ps.hasNotPaymentEnded,
  ]).pipe(
    map(
      ([
        currentTab,
        hasNotIncompleteAndEndedPayments,
        hasIncompleteAndEndedPayments,
        hasNotPaymentEnded,
      ]) =>
        currentTab === 'Active' &&
        (hasNotIncompleteAndEndedPayments ||
          hasIncompleteAndEndedPayments ||
          !hasNotPaymentEnded),
    ),
  );

  private initBotsList(botId: number): void {
    this.ss.spinnerSidebar$.next(true);
    this.ss.getEnabledSlugs(botId).subscribe();
    this.ss.getDisabledSlugs(botId).subscribe();
    this.ss.getFreeSlugsCount().subscribe();
  }

  public loadBotData(bot: { key: number }): void {
    this.mx.track('click_choose_bot', 'automations');
    this.ss.selectedSlug$.next(undefined);
    this.spinnerService.open();
    this.initBotsList(bot.key);
    this.spinnerService.close();
  }

  getPositionStyle() {
    if (window.innerWidth <= 600 || window.innerHeight <= 440) {
      return this.prs.showAutomationGuide ? 'absolute' : 'fixed';
    }
    return 'relative';
  }

  leaveWithoutSave(): void {
    const slug = this.currentSlug;
    this.ss.newSlugAdding = false;
    this.ss.hasUnsavedChanges$.next(false);

    if (this.ss.nextRouteState) {
      this.router.navigateByUrl(this.ss.nextRouteState.url);
      this.ss.selectSlug(undefined);
    } else if (this.ss.nextRouteState === undefined) {
      this.ss.selectSlug(slug);
    }
  }

  leaveWithSave(): void {
    this.uploadWidgetService.initializeChatWidget(this.merchantApiKey);

    const slug = this.ss.selectedSlug$.value;

    if (slug) {
      this.ns.setAnswerWarning(slug);
      this.ns.setContentWarning(slug);
      if (!this.ns.contentWarning && !this.ns.answerWarning) {
        if (slug.id !== 0) {
          if (slug.type === 'manual') {
            this.ss.updateFullManualSlug(slug);
          } else if (slug.type === 'automatic') {
            this.ss.updateFullAutoSlug(slug);
          }
        } else {
          if (slug.type === 'manual') {
            this.ss.createFullManualSlug(slug);
          } else if (slug.type === 'automatic') {
            this.ss.createFullAutoSlug(slug);
          }
        }
        if (this.ss.nextRouteState) {
          this.router.navigateByUrl(this.ss.nextRouteState.url);
          this.ss.selectSlug(undefined);
        } else {
          this.currentSlug && this.ss.selectSlug(this.currentSlug);
        }
      }
    }
  }

  closeLeavingWithoutSaveModal(): void {
    this.ss.hasUnsavedChanges$.next(false);
  }

  getSlugData(slug: Slug): void {
    if (this.ss.nextRouteState) {
      this.ss.nextRouteState = undefined;
    }
    this.ss.slugCreationStep$.next('closed');
    this.currentSlug = slug;
    if (this.ss.selectedSlug$.value?.hasUnsavedChanges) {
      this.ss.hasUnsavedChanges$.next(true);
    } else {
      this.ss.selectSlug(slug);
    }
  }

  closePopup(): void {
    this.ss.subscriptionError$.next('');
    this.currentSlug && this.currentSlug.updateActiveState(false);
  }

  ngOnDestroy(): void {
    this.ss.enabledSlugs$.next([]);
    this.ss.disabledSlugs$.next([]);
  }
}
