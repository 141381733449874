import { Injectable } from '@angular/core';
import { Slug } from '../domain/Slug';
import { AnswersService } from './automations/answers.service';
import { SlugsService } from './automations/slugs.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  answerWarning = false;
  contentWarning = false;
  constructor(
    private as: AnswersService,
    private ss: SlugsService,
  ) {}

  setContentWarning(slug: Slug) {
    if (slug)
      if (slug && slug.type === 'automatic' && !slug.content.content) {
        this.contentWarning = true;
      }
  }

  setAnswerWarning(slug: Slug) {
    if (slug)
      if (slug && slug.type === 'manual' && this.as.hasSingleEmptyAnswer()) {
        this.answerWarning = true;
      }
  }

  closeAnswerWarningModal(): void {
    this.answerWarning = false;
    this.ss.hasUnsavedChanges$.next(false);
  }

  closeContentWarningModal(): void {
    this.contentWarning = false;
    this.ss.hasUnsavedChanges$.next(false);
  }
}
