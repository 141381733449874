<ng-container>
  <form
    [formGroup]="inputWidgetForm"
    (change)="saveFormWidget()"
    class="input-form"
  >
    <norby-switcher
      (toggle)="toggleForm()"
      [label]="'Mandatory'"
      [switcherPosition]="'right'"
      [switchedOn]="isMandatory$.value"
    ></norby-switcher>
    <div class="input-inner">
      <div class="required-flex">
        <input
          #description
          formControlName="description"
          class="button-input input-description"
          type="text"
          placeholder="Enter a description …"
          (blur)="onBlur()"
        />
        <small class="required-asterisk">*</small>
      </div>
      <div class="widget-input">
        <input
          [readOnly]="true"
          formControlName="placeholder"
          class="button-input disabled"
          type="text"
        />
        <norby-bin
          (remove)="removeFormInput()"
          [showBin]="true"
          [item]="'Widget'"
        ></norby-bin>
      </div>
    </div>
  </form>
</ng-container>
<norby-modal
  *ngIf="ss.savingWidget && !inputWidgetForm.valid && inputWidgetForm.touched"
  (closeModal)="closeModalWindow()"
  ><div>
    <h4 class="modal-title">Fill all required fields.</h4>
    <div class="modal-content">
      <p *ngIf="!inputWidgetForm.get('description')?.value">
        Field "Enter a description …" is required.
      </p>
    </div>
    <button class="main-button" (click)="closeModalWindow()">Got it!</button>
  </div></norby-modal
>
