import { Component, Input } from '@angular/core';
import { Slug } from '../../../../@core/domain/Slug';
import { SlugsService } from '../../../../@core/services/automations/slugs.service';

@Component({
  selector: 'norby-slug-card',
  templateUrl: './slug-card.component.html',
  styleUrls: ['./slug-card.component.css'],
})
export class SlugCardComponent {
  @Input() slug!: Slug;

  constructor(protected ss: SlugsService) {}

  convertMessage(message: string): string {
    return message
      .replace(/<\/p>\s*<p>/g, ' </p> <p> ')
      .replace(/<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g, '');
  }
}
