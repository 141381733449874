import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { NgxFlatpickrWrapperComponent } from 'ngx-flatpickr-wrapper';
import { NgxHotjarModule } from 'ngx-hotjar';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { QuillModule } from 'ngx-quill';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { GetAccessToken } from './@core/services/auth.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AgentAvatarComponent } from './components/agent-avatar/agent-avatar.component';
import { AgentCardComponent } from './components/agent-card/agent-card.component';
import { AssignDropdownComponent } from './components/assign-dropdown/assign-dropdown.component';
import { AttributeInputComponent } from './components/attribute-input/attribute-input.component';
import { AttributeTextareaComponent } from './components/attribute-textarea/attribute-textarea.component';
import { AutocompleteSelectComponent } from './components/autocomplete-select/autocomplete-select.component';
import { MarkComponent } from './components/automations/main-window/mark/mark.component';
import { QuestionBoxComponent } from './components/automations/main-window/question-box/question-box.component';
import { QuestionCardComponent } from './components/automations/main-window/question-card/question-card.component';
import { SlugAnswerComponent } from './components/automations/main-window/slug-answer/slug-answer.component';
import { SlugContentHeaderComponent } from './components/automations/main-window/slug-content-header/slug-content-header.component';
import { SlugManualTriggerComponent } from './components/automations/main-window/slug-manual-trigger/slug-manual-trigger.component';
import { SlugNameComponent } from './components/automations/main-window/slug-name/slug-name.component';
import { SlugQuestionsWrapperComponent } from './components/automations/main-window/slug-questions-wrapper/slug-questions-wrapper.component';
import { SlugTextareaComponent } from './components/automations/main-window/slug-textarea/slug-textarea.component';
import { SlugTypeCreationComponent } from './components/automations/main-window/slug-type-creation/slug-type-creation.component';
import { AnswersWidgetComponent } from './components/automations/main-window/slug-widgets/answers-widget/answers-widget.component';
import { AutomationWidgetsComponent } from './components/automations/main-window/slug-widgets/automation-widgets/automation-widgets.component';
import { ButtonWidgetComponent } from './components/automations/main-window/slug-widgets/button-widget/button-widget.component';
import { InputWidgetComponent } from './components/automations/main-window/slug-widgets/input-widget/input-widget.component';
import { NewChatButtonWidgetComponent } from './components/automations/main-window/slug-widgets/new-chat-button-widget/new-chat-button-widget.component';
import { ProductWidgetComponent } from './components/automations/main-window/slug-widgets/product-widget/product-widget.component';
import { WidgetSelectComponent } from './components/automations/main-window/slug-widgets/widget-select/widget-select.component';
import { WidgetWrapperComponent } from './components/automations/main-window/slug-widgets/widget-wrapper/widget-wrapper.component';
import { SlugWrapperComponent } from './components/automations/main-window/slug-wrapper/slug-wrapper.component';
import { AutomaticSlugComponent } from './components/automations/main-window/slugs-types/automatic-slug/automatic-slug.component';
import { ManualSlugComponent } from './components/automations/main-window/slugs-types/manual-slug/manual-slug.component';
import { TriggerSelectComponent } from './components/automations/main-window/trigger-select/trigger-select.component';
import { NewSlugComponent } from './components/automations/sidebar/new-slug/new-slug.component';
import { SlugCardComponent } from './components/automations/sidebar/slug-card/slug-card.component';
import { SlugSearchComponent } from './components/automations/sidebar/slug-search/slug-search.component';
import { SlugsNotificationComponent } from './components/automations/sidebar/slugs-notification/slugs-notification.component';
import { SlugsTabsComponent } from './components/automations/sidebar/slugs-tabs/slugs-tabs.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { BillingStatusAlertComponent } from './components/billing-status-alert/billing-status-alert.component';
import { PromoCodeNotificationComponent } from './components/billing/promo-code-notification/promo-code-notification.component';
import { BinComponent } from './components/bin/bin.component';
import { BlueBarComponent } from './components/blue-bar/blue-bar.component';
import { ChatAttributesComponent } from './components/chat-attributes/chat-attributes.component';
import { ChatFormComponent } from './components/chat-widgets/chat-form/chat-form.component';
import { ProductListComponent } from './components/chat-widgets/product-list/product-list.component';
import { ProductButtonComponent } from './components/chat-widgets/product/product-button/product-button.component';
import { ProductComponent } from './components/chat-widgets/product/product.component';
import { ChatCardComponent } from './components/chat/chat-card/chat-card.component';
import { ChatMessageComponent } from './components/chat/chat-message/chat-message.component';
import { ClientDetailsComponent } from './components/chat/client-details/client-details.component';
import { OfflineBarComponent } from './components/chat/offline-bar/offline-bar.component';
import { ViewedComponent } from './components/chat/viewed/viewed.component';
import { CommentComponent } from './components/comment/comment.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { ClosedDropdownComponent } from './components/dropdown/closed-dropdown/closed-dropdown.component';
import { BotIconComponent } from './components/icons/bot-icon/bot-icon.component';
import { ClientIconComponent } from './components/icons/client-icon/client-icon.component';
import { CloseIconComponent } from './components/icons/close-icon/close-icon.component';
import { CogsComponent } from './components/icons/cogs/cogs.component';
import { ExclamationMarkComponent } from './components/icons/exclamtion-mark/exclamation-mark.component';
import { ExpandComponent } from './components/icons/expand/expand.component';
import { NumberOneIconComponent } from './components/icons/numbers/number-one-icon/number-one-icon.component';
import { NumberThreeIconComponent } from './components/icons/numbers/number-three-icon/number-three-icon.component';
import { NumberTwoIconComponent } from './components/icons/numbers/number-two-icon/number-two-icon.component';
import { RollComponent } from './components/icons/roll/roll.component';
import { ScrollButtonComponent } from './components/icons/scroll-button/scroll-button.component';
import { SendIconComponent } from './components/icons/send-icon.component';
import { UnionIconComponent } from './components/icons/union-icon/union-icon.component';
import { InputComponent } from './components/input/input.component';
import { LogoComponent } from './components/logo/logo.component';
import { MessageFormComponent } from './components/message-form/message-form.component';
import { ModalComponent } from './components/modal/modal.component';
import { ChooserFormComponent } from './components/navbar/chooser-form/chooser-form.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { NeedHelpMainComponent } from './components/need-help/need-help-main/need-help-main.component';
import { NeedHelpShadowComponent } from './components/need-help/need-help-shadow/need-help-shadow.component';
import { OnlineComponent } from './components/online/online.component';
import { SearchSelectComponent } from './components/search-select/search-select.component';
import { SearchComponent } from './components/search/search-chat/search.component';
import { SearchUserComponent } from './components/search/search-user/search-user.component';
import { SelectCountryComponent } from './components/select-country/select-country.component';
import { SelectWithCrumbsComponent } from './components/select-with-crumbs/select-with-crumbs.component';
import { SelectComponent } from './components/select/select.component';
import { SendButtonComponent } from './components/send-button/send-button.component';
import { BgBlobComponent } from './components/signup/sign-up-background/bg-blob/bg-blob.component';
import { BgChatLongComponent } from './components/signup/sign-up-background/bg-chat-long/bg-chat-long.component';
import { BgChatComponent } from './components/signup/sign-up-background/bg-chat/bg-chat.component';
import { BgLetterComponent } from './components/signup/sign-up-background/bg-letter/bg-letter.component';
import { BgNoiseComponent } from './components/signup/sign-up-background/bg-noise/bg-noise.component';
import { FirstStepComponent } from './components/signup/sign-up-steps/first-step/first-step.component';
import { SecondStepComponent } from './components/signup/sign-up-steps/second-step/second-step.component';
import { ThirdStepComponent } from './components/signup/sign-up-steps/third-step/third-step.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { StatisticNumberComponent } from './components/statistic-number/statistic-number.component';
import { StatisticComponent } from './components/statistic/statistic.component';
import { NoChatStubComponent } from './components/stubs/no-chat-stub/no-chat-stub.component';
import { ChooseActionsComponent } from './components/three-dots/choose-actions/choose-actions.component';
import { ContextActionsComponent } from './components/three-dots/context-actions/context-actions.component';
import { ThreeDotsComponent } from './components/three-dots/three-dots.component';
import { AccentButtonComponent } from './components/ui/accent-button/accent-button.component';
import { AccordionComponent } from './components/ui/accordion/accordion.component';
import { AddNewButtonComponent } from './components/ui/add-new-button/add-new-button.component';
import { ArrowComponent } from './components/ui/arrow/arrow.component';
import { AttributeListItemComponent } from './components/ui/attribute-list-item/attribute-list-item.component';
import { AttributesTitleComponent } from './components/ui/attributes-title/attributes-title.component';
import { CancelIconComponent } from './components/ui/cancel-icon/cancel-icon.component';
import { CenteredImageComponent } from './components/ui/centered-image/centered-image.component';
import { ClockIconComponent } from './components/ui/clock-icon/clock-icon.component';
import { CopyButtonComponent } from './components/ui/copy-button/copy-button.component';
import { DebounceInputComponent } from './components/ui/debounce-input/debounce-input.component';
import { DotsButtonComponent } from './components/ui/dots-button/dots-button.component';
import { DownArrowButtonComponent } from './components/ui/down-arrow-button/down-arrow-button.component';
import { ExposeButtonComponent } from './components/ui/expose-button/expose-button.component';
import { ExternalLinkIconComponent } from './components/ui/external-link-icon/external-link-icon.component';
import { EyeIconComponent } from './components/ui/eye-icon/eye-icon.component';
import { NoChatsArrowComponent } from './components/ui/no-chats-arrow/no-chats-arrow.component';
import { PictureIconComponent } from './components/ui/picture-icon/picture-icon.component';
import { PointerArrowComponent } from './components/ui/pointer-arrow/pointer-arrow.component';
import { QuestionCircleComponent } from './components/ui/question-circle/question-circle.component';
import { RadioButtonComponent } from './components/ui/radio-button/radio-button.component';
import { RemoveAllBinComponent } from './components/ui/remove-all-bin/remove-all-bin.component';
import { SearchResetComponent } from './components/ui/seacrh-reset/search-reset.component';
import { SearchButtonComponent } from './components/ui/search-button/search-button.component';
import { SmallCopyButtonComponent } from './components/ui/small-copy-button/small-copy-button.component';
import { SwitcherComponent } from './components/ui/switcher/switcher.component';
import { TextAreaComponent } from './components/ui/text-area/text-area.component';
import { ToastComponent } from './components/ui/toast/toast.component';
import { UpdateButtonComponent } from './components/ui/update-button/update-button.component';
import { UserWidgetComponent } from './components/user-widget/user-widget.component';
import { WidgetCodeBoxComponent } from './components/widget-code-box/widget-code-box.component';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { AutoResizeDirective } from './directives/auto-resize.directive';
import { AutoWidthResizeDirective } from './directives/auto-width-resize.directive';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { ImagePreloadDirective } from './directives/image-preload.directive';
import { httpInterceptorProviders } from './http-interceptors';
import { SlugWindowComponent } from './pages/automations-page/slug-window/slug-window.component';
import { SlugsMainPageComponent } from './pages/automations-page/slugs-main-page/slugs-main-page.component';
import { SlugsSidebarComponent } from './pages/automations-page/slugs-sidebar/slugs-sidebar.component';
import { ChatsPageComponent } from './pages/chats-page/chats-page.component';
import { LayoutComponent } from './pages/layout/layout.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { NewPasswordComponent } from './pages/new-password/new-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { SetPasswordPageComponent } from './pages/set-password-page/set-password-page.component';
import { AttributeRemovePopupComponent } from './pages/settings-page/attributes-page/attribute/attribute-remove-popup/attribute-remove-popup.component';
import { AttributeComponent } from './pages/settings-page/attributes-page/attribute/attribute.component';
import { AttributesChatComponent } from './pages/settings-page/attributes-page/attributes-chat/attributes-chat.component';
import { AttributeAddPopupComponent } from './pages/settings-page/attributes-page/attributes-header/attribute-add-popup/attribute-add-popup.component';
import { AttributesHeaderComponent } from './pages/settings-page/attributes-page/attributes-header/attributes-header.component';
import { AttributesUserComponent } from './pages/settings-page/attributes-page/attributes-user/attributes-user.component';
import { BillingInfoComponent } from './pages/settings-page/billing-page/billing-info/billing-info.component';
import { BillingPageComponent } from './pages/settings-page/billing-page/billing-page.component';
import { LanguagesSettingsPageComponent } from './pages/settings-page/languages-settings-page/languages-settings-page.component';
import { ProjectSettingsPageComponent } from './pages/settings-page/project-settings-page/project-settings-page.component';
import { SettingsPageMainComponent } from './pages/settings-page/settings-page-main/settings-page-main.component';
import { SettingsPageSidebarComponent } from './pages/settings-page/settings-page-sidebar/settings-page-sidebar.component';
import { SettingsPageComponent } from './pages/settings-page/settings-page/settings-page.component';
import { SignupPageComponent } from './pages/signup-page/signup-page.component';
import { TeamPageComponent } from './pages/team-page/team-page.component';
import { UserSearchPageComponent } from './pages/user-search-page/user-search-page.component';
import { AgentSearchFilterPipe } from './pipes/agent-search-filter.pipe';
import { FromNumberToMonthPipe } from './pipes/from-number-to-month.pipe';
import { LocaleConverterPipe } from './pipes/locale-converter.pipe';
import { RemoveSpacesPipe } from './pipes/remove-spaces.pipe';
import { TeamAssignStrConverterPipe } from './pipes/team-assign-str-converter.pipe';
import { TotalMinutesToTimeStringPipe } from './pipes/total-minutes-to-time-string.pipe';
import { TotalSecondToTimeStringPipe } from './pipes/total-second-to-time-string.pipe';
import { GuideModalComponent } from './components/guide-modal/guide-modal.component';
import { MessageWidgetComponent } from './components/automations/main-window/slug-widgets/message-widget/message-widget.component';
import { FourthStepComponent } from './components/signup/sign-up-steps/fourth-step/fourth-step.component';
import { VerifyEmailComponent } from './components/signup/verify-email/verify-email.component';
import { ReferralPageComponent } from './pages/referral-page/referral-page.component';

const conditionalImports = [];

// Hotjar Tracking Code for https://chat.norby.io
if (document && document.location.href.includes('chat.norby.io')) {
  conditionalImports.push(NgxHotjarModule.forRoot('2334793'));
}

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    TeamPageComponent,
    LoginPageComponent,
    InputComponent,
    AvatarComponent,
    ChatCardComponent,
    ChatsPageComponent,
    LayoutComponent,
    SettingsPageComponent,
    ChatMessageComponent,
    ModalComponent,
    OnlineComponent,
    TotalMinutesToTimeStringPipe,
    DatepickerComponent,
    StatisticComponent,
    StatisticNumberComponent,
    AssignDropdownComponent,
    TotalSecondToTimeStringPipe,
    SpinnerComponent,
    AgentCardComponent,
    AgentAvatarComponent,
    SetPasswordPageComponent,
    MessageFormComponent,
    ProductComponent,
    ClientDetailsComponent,
    ProductListComponent,
    BotIconComponent,
    ScrollButtonComponent,
    OfflineBarComponent,
    ResetPasswordComponent,
    NewPasswordComponent,
    BlueBarComponent,
    ExclamationMarkComponent,
    ImagePreloadDirective,
    ClosedDropdownComponent,
    SwitcherComponent,
    ViewedComponent,
    CenteredImageComponent,
    ChooserFormComponent,
    RadioButtonComponent,
    AccentButtonComponent,
    ClientIconComponent,
    ChatAttributesComponent,
    CogsComponent,
    RollComponent,
    ExpandComponent,
    SettingsPageSidebarComponent,
    SettingsPageMainComponent,
    AccordionComponent,
    AttributeComponent,
    AutoFocusDirective,
    TextAreaComponent,
    AttributesHeaderComponent,
    UpdateButtonComponent,
    SelectComponent,
    NoChatStubComponent,
    AttributeInputComponent,
    AttributeTextareaComponent,
    BinComponent,
    AttributeRemovePopupComponent,
    AttributeAddPopupComponent,
    AttributesChatComponent,
    AttributesUserComponent,
    SearchComponent,
    SearchButtonComponent,
    SearchSelectComponent,
    ExposeButtonComponent,
    ProjectSettingsPageComponent,
    UserSearchPageComponent,
    SearchUserComponent,
    UserWidgetComponent,
    AttributesTitleComponent,
    AttributeListItemComponent,
    CommentComponent,
    LogoComponent,
    AgentSearchFilterPipe,
    QuestionCircleComponent,
    AutoResizeDirective,
    DebounceInputComponent,
    SearchResetComponent,
    DotsButtonComponent,
    ThreeDotsComponent,
    ChooseActionsComponent,
    ContextActionsComponent,
    SlugsMainPageComponent,
    SlugsSidebarComponent,
    SlugWindowComponent,
    NewSlugComponent,
    SlugsTabsComponent,
    SlugCardComponent,
    AddNewButtonComponent,
    PointerArrowComponent,
    ClockIconComponent,
    SlugWrapperComponent,
    DownArrowButtonComponent,
    QuestionCardComponent,
    QuestionBoxComponent,
    MarkComponent,
    SlugAnswerComponent,
    SlugNameComponent,
    SlugQuestionsWrapperComponent,
    CancelIconComponent,
    PictureIconComponent,
    ButtonWidgetComponent,
    MessageWidgetComponent,
    InputWidgetComponent,
    ProductWidgetComponent,
    AnswersWidgetComponent,
    WidgetWrapperComponent,
    NewChatButtonWidgetComponent,
    WidgetSelectComponent,
    AutomationWidgetsComponent,
    ClickOutsideDirective,
    AutoWidthResizeDirective,
    SignupPageComponent,
    BgNoiseComponent,
    BgLetterComponent,
    BgChatComponent,
    BgChatLongComponent,
    BgBlobComponent,
    ArrowComponent,
    FirstStepComponent,
    SecondStepComponent,
    ThirdStepComponent,
    EyeIconComponent,
    BillingPageComponent,
    ExternalLinkIconComponent,
    SlugsNotificationComponent,
    CopyButtonComponent,
    WidgetCodeBoxComponent,
    SlugTypeCreationComponent,
    AutomaticSlugComponent,
    SlugContentHeaderComponent,
    SlugTextareaComponent,
    ManualSlugComponent,
    ProductButtonComponent,
    SlugSearchComponent,
    UnionIconComponent,
    BillingStatusAlertComponent,
    FromNumberToMonthPipe,
    PromoCodeNotificationComponent,
    SlugManualTriggerComponent,
    TriggerSelectComponent,
    TeamAssignStrConverterPipe,
    NeedHelpMainComponent,
    CloseIconComponent,
    NumberOneIconComponent,
    NumberTwoIconComponent,
    NumberThreeIconComponent,
    NeedHelpShadowComponent,
    LanguagesSettingsPageComponent,
    SelectWithCrumbsComponent,
    LocaleConverterPipe,
    BillingInfoComponent,
    AutocompleteSelectComponent,
    RemoveSpacesPipe,
    ChatFormComponent,
    SendIconComponent,
    ToastComponent,
    SendButtonComponent,
    SelectCountryComponent,
    NoChatsArrowComponent,
    SmallCopyButtonComponent,
    RemoveAllBinComponent,
    GuideModalComponent,
    CloseIconComponent,
    FourthStepComponent,
    VerifyEmailComponent,
    ReferralPageComponent,
  ],
  imports: [
    ...conditionalImports,
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: GetAccessToken,
        allowedDomains: [environment.endpoint],
      },
    }),
    AppRoutingModule,
    NgxFlatpickrWrapperComponent,
    InfiniteScrollModule,
    CommonModule,
    FormsModule,
    QuillModule.forRoot(),
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      toastComponent: ToastComponent,
      timeOut: 3000,
    }),
    GoogleTagManagerModule.forRoot({
      id: 'GTM-WWZNDSN',
    }),
  ],
  providers: [httpInterceptorProviders],
  bootstrap: [AppComponent],
  exports: [NavbarComponent],
})
export class AppModule {}
