<ng-container
  *ngIf="{
    lang: uls.currentLanguage$ | async,
    widgetOptions: widgetOptions$ | async
  } as ctx"
>
  <form class="select-box" *ngIf="!currentWidgetType && ctx.widgetOptions">
    <norby-select
      (setChangeValue)="selectWidgetType($event)"
      [placeholder]="'Choose a widget ...'"
      [style]="{ width: '100%' }"
      [topPositioning]="'-265px'"
      [optionValues]="ctx.widgetOptions"
      [textColor]="'var(--light-text-color)'"
    ></norby-select>
  </form>

  <norby-message-widget
    *ngIf="currentWidgetType === 'message'"
    [widgetDetails]="widget"
    (remove)="removeWidget()"
    (save)="createWidget($event)"
    (update)="update($event)"
  >
  </norby-message-widget>

  <norby-button-widget
    *ngIf="currentWidgetType === 'button'"
    [widgetDetails]="widget"
    (remove)="removeWidget()"
    (save)="createWidget($event)"
    (update)="update($event)"
  >
  </norby-button-widget>

  <norby-new-chat-button-widget
    *ngIf="currentWidgetType === 'new_chat_button'"
    [widgetDetails]="widget"
    (remove)="removeWidget()"
    (save)="createWidget($event)"
  >
  </norby-new-chat-button-widget>

  <norby-input-widget
    *ngIf="currentWidgetType === 'form'"
    [widgetDetails]="widget"
    (remove)="removeWidget()"
    (save)="createWidget($event)"
    (update)="update($event)"
  >
  </norby-input-widget>

  <norby-product-widget
    *ngIf="currentWidgetType === 'product'"
    [widgetDetails]="widget"
    widgetType="product"
    (remove)="removeWidget()"
    (save)="createWidget($event)"
    (update)="update($event)"
  >
  </norby-product-widget>

  <norby-product-widget
    *ngIf="currentWidgetType === 'product_list'"
    [widgetDetails]="widget"
    widgetType="product_list"
    (remove)="removeWidget()"
    (save)="createWidget($event)"
    (update)="update($event)"
  >
  </norby-product-widget>

  <norby-answers-widget
    *ngIf="currentWidgetType === 'predefined_answers'"
    [widgetDetails]="widget"
    (remove)="removeWidget()"
    (save)="createWidget($event)"
    (update)="update($event)"
  >
  </norby-answers-widget>
</ng-container>
