import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { map } from 'rxjs';
import { getIdByLocale } from '../../../../../@core/domain/Locale';
import { Slug } from '../../../../../@core/domain/Slug';
import { UserLanguagesService } from '../../../../../@core/services/user-languages.service';
import { WidgetRequestData } from '../../../../../@core/types/Automations/Widgets/WidgetRequest';
import {
  Widget,
  WidgetType,
} from '../../../../../@core/types/Automations/Widgets/Widget';
import { SlugsApiService } from '../../../../../@core/services/api/slugs-api.service';

@Component({
  selector: 'norby-widget-wrapper',
  templateUrl: './widget-wrapper.component.html',
  styleUrls: ['./widget-wrapper.component.css'],
})
export class WidgetWrapperComponent implements OnInit {
  @Input() widget: Widget | undefined;
  @Input() slugId: Slug['id'] | undefined;
  @Output() saveWidget = new EventEmitter();
  @Output() deleteWidget = new EventEmitter();
  @Output() updateWidget = new EventEmitter();

  currentWidgetType: WidgetType | undefined = undefined;

  constructor(
    private sas: SlugsApiService,
    protected uls: UserLanguagesService,
  ) {}

  ngOnInit(): void {
    if (this.widget) {
      this.currentWidgetType = this.widget.type;
    }
  }

  public widgetType$ = this.sas.getWidgetTypes();

  public widgetOptions$ = this.widgetType$.pipe(
    map((w) =>
      Object.entries(w).map(([key, value]) => ({
        key,
        value,
      })),
    ),
  );

  selectWidgetType({ key }: { key: string }) {
    this.currentWidgetType = key as WidgetType;
  }

  public createWidget(widget: Pick<WidgetRequestData, 'widget'>): void {
    this.saveWidget.emit({
      slug_id: this.slugId,
      widget,
      type: this.currentWidgetType,
      language_id: getIdByLocale(this.uls.currentLanguage$.value),
    });
  }

  public update(widget: Pick<WidgetRequestData, 'widget'>): void {
    if (this.slugId && this.widget) {
      this.updateWidget.emit({
        slug_id: this.slugId,
        id: this.widget.id,
        type: this.currentWidgetType,
        language_id: getIdByLocale(this.uls.currentLanguage$.value),
        widget: widget,
      });
    }
  }

  public removeWidget(): void {
    this.deleteWidget.emit();
    this.currentWidgetType = undefined;
  }
}
