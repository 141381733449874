import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { OnlineStatus, Profile, ProfileUpdateValues } from '../domain/Profile';
import { ProfileApiService } from './api/profile-api.service';
import { MixpanelService } from './mixpanel.service';
import { SpinnerService } from './spinner.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  profile$: BehaviorSubject<Profile | null> =
    new BehaviorSubject<Profile | null>(null);
  loadingState$: BehaviorSubject<boolean> = this.spinnerService.show$;
  showAutomationGuide = false;

  constructor(
    private profileApi: ProfileApiService,
    private mx: MixpanelService,
    private spinnerService: SpinnerService,
  ) {}

  public updateProfile(next: ProfileUpdateValues) {
    if (!this.profile$.value) {
      return;
    }
    this.spinnerService.open();
    const currentProfile = this.profile$.value;
    currentProfile?.update(next);
    return this.profileApi.update(currentProfile.updatableValues()).pipe(
      switchMap(() => this.reload()),
      catchError((error) => {
        console.log(`Error updating profile: ${error.message}`);
        return of(null);
      }),
      finalize(() => this.spinnerService.close()),
    );
  }

  public changePassword(password: string, callback?: CallableFunction): void {
    if (!this.profile$.value) {
      return;
    }
    const currentProfile = this.profile$.value;
    currentProfile?.changePassword(password);
    this.spinnerService.open();
    this.profileApi.update(currentProfile.updatableValues()).subscribe({
      next: () => {
        this.profile$.next(currentProfile);
        this.spinnerService.close();
        if (callback) {
          callback();
        }
      },
      error: (error) => {
        this.spinnerService.close();
        console.log(`Error updating profile: ${error.message}`);
      },
      complete: () => this.spinnerService.close(),
    });
  }

  public reload(): Observable<Profile> {
    return this.profileApi.getInfo().pipe(
      map((dto) => new Profile(dto)),
      tap((profile) => {
        this.profile$.next(profile);
        localStorage.setItem('role', profile.role);
        this.mx.init(profile);
      }),
    );
  }

  public setAwayStatus(status: OnlineStatus): void {
    const current = this.profile$.value;
    if (current) {
      current.status = status;
      this.profile$.next(current);
    }
  }

  public updateGuideStatus(status: boolean): void {
    if (!this.profile$.value) {
      return;
    }
    this.spinnerService.open();
    const currentProfile = this.profile$.value;
    currentProfile?.updateGuidStatus(status);
    this.profileApi.update(currentProfile?.updatableValues()).subscribe(
      () => {
        this.profile$.next(currentProfile);
        this.spinnerService.close();
      },
      (error) => {
        this.spinnerService.close();
        console.log(`Error updating profile: ${error.message}`);
      },
    );
  }

  public updateVisitedAutomationsPage(visited: boolean): void {
    if (!this.profile$.value) {
      return;
    }
    const currentProfile = this.profile$.value;
    if (currentProfile) {
      currentProfile.hasVisitedAutomationsPage(visited);
      this.profileApi.update(currentProfile.updatableValues()).subscribe(
        () => {
          this.profile$.next(currentProfile);
        },
        (error) => {
          console.log(`Error updating profile: ${error.message}`);
        },
      );
    }
  }

  public closeAutomationGuide(): void {
    this.showAutomationGuide = false;
    this.updateVisitedAutomationsPage(false);
  }
}
