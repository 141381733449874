import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ProjectSettingsResponse } from '../types/ProjectSettingsResponse';
import { ProjectSettingsService } from './settings/project-settings.service';
import { UntypedFormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class SettingsPageService {
  public showContent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  public apiKey;
  public projectToken;
  public locale$ = this.ps.projectSettings.locale$;
  private baseUrl = environment.endpoint;

  constructor(
    private http: HttpClient,
    private ps: ProjectSettingsService,
  ) {
    this.apiKey = this.ps.projectSettings.apiKey$;
    this.projectToken = this.ps.projectSettings.projectToken$;
    this.initProjectSettingsView();
  }

  public getProjectSettings(): Observable<ProjectSettingsResponse> {
    return this.http.get<ProjectSettingsResponse>(
      `${this.baseUrl}/api/panel/settings`,
    );
  }

  public setDefaultLocale(body: { locale: string }) {
    return this.http.put<ProjectSettingsResponse>(
      `${this.baseUrl}/api/panel/settings`,
      body,
    );
  }

  public connectDiscord(body: UntypedFormGroup) {
    return this.http.post<ProjectSettingsResponse>(
      `${environment.endpoint}/api/discord/setup`,
      body,
    );
  }

  public initProjectSettingsView(): void {
    this.http
      .get<ProjectSettingsResponse>(`${this.baseUrl}/api/panel/settings`)
      .subscribe({
        next: (result) => {
          this.ps.updateValues(result);
        },
        error: (error) => {
          console.error('Error getiing project settings: ', error);
        },
      });
  }
}
