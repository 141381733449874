import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PaymentService } from '../../../@core/services/automations/payment.service';
import { MixpanelService } from '../../../@core/services/mixpanel.service';
import { ProfileService } from '../../../@core/services/profile.service';
import { SettingsPageService } from '../../../@core/services/settings-page.service';
import { ProjectSettingsService } from '../../../@core/services/settings/project-settings.service';
import { SpinnerService } from '../../../@core/services/spinner.service';
import {
  FormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'norby-project-settings-page',
  templateUrl: './project-settings-page.component.html',
  styleUrls: ['./project-settings-page.component.css'],
})
export class ProjectSettingsPageComponent implements OnInit, OnDestroy {
  public widgetCode = '';
  private sub = new Subscription();
  public isModalOpen = false;
  public isLoaded = false;

  public form: UntypedFormGroup;

  constructor(
    protected ss: SettingsPageService,
    protected spinnerService: SpinnerService,
    private ps: PaymentService,
    private mx: MixpanelService,
    protected pss: ProjectSettingsService,
    protected profileService: ProfileService,
    protected formBuilder: FormBuilder,
  ) {
    this.form = this.formBuilder.group({
      applicationId: ['', [Validators.required]],
      applicationPublicKey: ['', [Validators.required]],
      botToken: ['', [Validators.required]],
    });
  }

  public getControl(controlName: string): UntypedFormControl {
    return this.form.controls[controlName] as UntypedFormControl;
  }

  ngOnInit(): void {
    this.sub.add(
      this.ps.getUserLimits().subscribe((limits) => {
        this.pss.getAutomationLimits(limits);
      }),
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  getCode(code: string): void {
    this.widgetCode = code;
  }

  sendMixPanelEvent(event: string, page: string): void {
    this.mx.track(event, page);
  }

  addMixPanelEvent(): void {
    this.mx.track('click_how_to_customize_widget', 'settings/project');
  }

  connectDiscord(form: UntypedFormGroup): void {
    this.isLoaded = true;
    this.ss.connectDiscord(form).subscribe({
      next: () => {
        this.ss.initProjectSettingsView();
        this.form.reset();
      },
      error: (error) => {
        console.log(error, 'Can not setup discord');
      },
      complete: () => {
        this.isLoaded = false;
        this.isModalOpen = false;
      },
    });
  }

  openModal(): void {
    this.isModalOpen = true;
  }
}
