import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { SlugsService } from '../../../../../@core/services/automations/slugs.service';
import { Widget } from '../../../../../@core/types/Automations/Widgets/Widget';
import { MessageDetails } from '../../../../../@core/types/Automations/Widgets/MessageDetails';

@Component({
  selector: 'norby-message-widget',
  templateUrl: './message-widget.component.html',
  styleUrls: ['./message-widget.component.css'],
})
export class MessageWidgetComponent implements OnChanges, AfterViewInit {
  @Output() save = new EventEmitter();
  @Output() remove = new EventEmitter();
  @Output() update = new EventEmitter();
  @Input() widgetDetails: Widget | undefined;

  @ViewChild('message') message: ElementRef | undefined;

  widgetMessageForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    protected ss: SlugsService,
  ) {
    this.widgetMessageForm = this.fb.group({
      message: ['', Validators.required],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widgetDetails?.currentValue) {
      const widget = changes.widgetDetails.currentValue as Widget;
      if (
        widget.widget &&
        (widget.widget as MessageDetails).message !== undefined
      ) {
        this.widgetMessageForm.setValue({
          message: (widget.widget as MessageDetails).message,
        });
      }
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (
        this.message &&
        document.activeElement !== this.message.nativeElement &&
        this.message.nativeElement.value === ''
      ) {
        this.message.nativeElement.focus();
      }
    }, 0);
  }

  onBlur() {
    if (this.message) {
      this.message.nativeElement.blur();
    }
  }

  changeMessageWidget(): void {
    if (this.widgetMessageForm.valid) {
      const rawValue = this.widgetMessageForm.getRawValue();
      if (this.widgetDetails?.id) {
        this.update.emit(rawValue);
      } else {
        this.save.emit(rawValue);
      }
    }
  }

  closeModalWindow(): void {
    if (this.ss.savingWidget) {
      this.ss.savingWidget = false;
    }
  }

  removeButton(): void {
    this.remove.emit();
  }
}
