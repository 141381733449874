import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SlugsService } from '../../../../../@core/services/automations/slugs.service';
import { UserLanguagesService } from '../../../../../@core/services/user-languages.service';
import { FormDetails } from '../../../../../@core/types/Automations/Widgets/FormDetails';
import { BehaviorSubject } from 'rxjs';
import { Widget } from '../../../../../@core/types/Automations/Widgets/Widget';

@Component({
  selector: 'norby-input-widget',
  templateUrl: './input-widget.component.html',
  styleUrls: ['./input-widget.component.css'],
})
export class InputWidgetComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  inputWidgetForm: FormGroup;
  currentLanguage = this.uls.currentLanguage$.value;
  isMandatory$ = new BehaviorSubject<boolean>(false);
  @Output() save = new EventEmitter();
  @Output() remove = new EventEmitter();
  @Output() update = new EventEmitter();
  @Input() widgetDetails: Widget | undefined;
  @ViewChild('description') description: ElementRef | undefined;
  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: HTMLElement): void {
    const clickedInside =
      this.description?.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.onBlur();
    }
  }

  constructor(
    private fb: FormBuilder,
    protected ss: SlugsService,
    private uls: UserLanguagesService,
  ) {
    this.inputWidgetForm = this.fb.group({
      description: ['', Validators.required],
      placeholder: [''],
      isMandatory: [this.isMandatory$.value],
    });

    this.isMandatory$.subscribe((value) => {
      this.inputWidgetForm.patchValue(
        { isMandatory: value },
        { emitEvent: false },
      );
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widgetDetails?.currentValue) {
      const widget = changes.widgetDetails.currentValue as Widget;
      if (
        widget.widget &&
        (widget.widget as FormDetails).description !== undefined &&
        (widget.widget as FormDetails).inputs.placeholder !== undefined &&
        (widget.widget as FormDetails).isMandatory !== undefined
      ) {
        this.inputWidgetForm.patchValue({
          description: (widget.widget as FormDetails).description,
          placeholder: (widget.widget as FormDetails).inputs.placeholder,
          isMandatory: (widget.widget as FormDetails).isMandatory,
        });
        this.isMandatory$.next((widget.widget as FormDetails).isMandatory);
      }
    }
  }

  ngOnInit(): void {
    switch (this.currentLanguage) {
      case 'en':
        this.inputWidgetForm.get('placeholder')?.setValue('Your email ...');
        return;
      case 'ee':
        this.inputWidgetForm.get('placeholder')?.setValue('Sinu e-mail ...');
        return;
      case 'ru':
        this.inputWidgetForm.get('placeholder')?.setValue('Введите почту ...');
        return;
      case 'it':
        this.inputWidgetForm
          .get('placeholder')
          ?.setValue("Inserisci l'email ...");
        return;
      case 'fr':
        this.inputWidgetForm
          .get('placeholder')
          ?.setValue('Entrer votre Email ...');
        return;
      case 'de':
        this.inputWidgetForm
          .get('placeholder')
          ?.setValue('Geben Sie Ihre E-Mail-Adresse ein ...');
        return;
      case 'ua':
        this.inputWidgetForm
          .get('placeholder')
          ?.setValue('Введіть свою електронну адресу ...');
        return;
      case 'es':
        this.inputWidgetForm
          .get('placeholder')
          ?.setValue('Introduce tu correo electrónico ...');
        return;
      case 'fi':
        this.inputWidgetForm
          .get('placeholder')
          ?.setValue('Syötä sähköpostiosoitteesi ...');
        return;
      case 'sv':
        this.inputWidgetForm.get('placeholder')?.setValue('Din email ...');
        return;
      case 'da':
        this.inputWidgetForm.get('placeholder')?.setValue('Din email ...');
        return;
      case 'no':
        this.inputWidgetForm.get('placeholder')?.setValue('Din epost ...');
        return;
      case 'pl':
        this.inputWidgetForm.get('placeholder')?.setValue('Twój email ...');
        return;
      case 'pt':
        this.inputWidgetForm.get('placeholder')?.setValue('Seu email ...');
        return;
      case 'tr':
        this.inputWidgetForm
          .get('placeholder')
          ?.setValue('E-posta adresiniz ...');
        return;
      case 'zh':
        this.inputWidgetForm.get('placeholder')?.setValue('你的郵件 ...');
        return;
      case 'ja':
        this.inputWidgetForm
          .get('placeholder')
          ?.setValue('あなたのメールアドレス ...');
        return;
      case 'ar':
        this.inputWidgetForm
          .get('placeholder')
          ?.setValue('بريدك الالكتروني ...');
        return;
      default:
        this.inputWidgetForm.get('placeholder')?.setValue('Your email ...');
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (
        this.description &&
        document.activeElement !== this.description.nativeElement &&
        this.description.nativeElement.value === ''
      ) {
        this.description.nativeElement.focus();
      }
    }, 0);
  }

  onBlur() {
    if (this.description) {
      this.description.nativeElement.blur();
    }
  }

  saveFormWidget(): void {
    if (this.inputWidgetForm.valid) {
      const inputData = this.setInputData(this.inputWidgetForm.getRawValue());

      const { inputs, isMandatory } = inputData;
      const fullData: FormDetails = {
        description: this.inputWidgetForm.controls.description.value,
        name: 'emailForm',
        submitHandler: 'emailFormHandler',
        inputs,
        isMandatory,
      };
      if (this.widgetDetails?.id) {
        this.update.emit(fullData);
      } else {
        this.save.emit(fullData);
      }
    }
  }

  setInputData(inputs: { placeholder: string; isMandatory: boolean }) {
    const { placeholder, isMandatory } = inputs;
    return { inputs: { placeholder }, isMandatory };
  }

  toggleForm(): void {
    const currentState = this.isMandatory$.value;
    this.isMandatory$.next(!currentState);
    this.saveFormWidget();
  }

  closeModalWindow(): void {
    if (this.ss.savingWidget) {
      this.ss.savingWidget = false;
    }
  }

  removeFormInput(): void {
    this.remove.emit();
  }

  ngOnDestroy() {
    this.isMandatory$.unsubscribe();
  }
}
