<norby-slug-wrapper
  [title]="'Answers'"
  [subtitle]="'Answer clients receive asking the questions above'"
  [trigger]="selectedSlugTrigger$ | async"
>
  <form
    *ngIf="ss.selectedSlug$ | async as selectedSlug"
    [formGroup]="answersForm"
  >
    <ng-container
      formArrayName="answers"
      *ngFor="let editor of editorsFormArray.controls; let i = index"
    >
      <div class="form-group" [formGroupName]="i">
        <label [for]="'text-' + i"></label>
        <div class="editor-container">
          <quill-editor
            (onContentChanged)="getContentValue()"
            [sanitize]="true"
            formControlName="text"
            [placeholder]="'Type in the answer…'"
            [modules]="quillModules"
          >
          </quill-editor>
          <div class="remove-button">
            <norby-bin
              (remove)="removeEditor(i)"
              [showBin]="true"
              [item]="'Answer'"
              [disabled]="editorsFormArray.length < 2"
            ></norby-bin>
          </div>
        </div>
      </div>
      <div class="error-container">
        <div *ngIf="editor.get('text')?.invalid && editor.get('text')?.touched">
          Answer cannot be empty.
        </div>
        <div *ngIf="ss.error$ | async as serverError">
          <div *ngIf="serverError?.has('answers.' + i + '.text')">
            {{ serverError?.get("answers." + i + ".text") }}
          </div>
        </div>
      </div>
    </ng-container>
    <button
      [disabled]="editorsFormArray.length === 10 || answersForm.invalid"
      class="secondary-button add-button"
      (click)="addEditor()"
    >
      Add a message
    </button>
  </form>
</norby-slug-wrapper>
