<form
  [formGroup]="widgetButtonForm"
  (change)="changeButtonWidget()"
  class="button-form"
>
  <div class="input-inner">
    <div class="required-flex">
      <input
        #name
        formControlName="name"
        class="button-input"
        type="text"
        placeholder="Enter the button text …"
        (blur)="onBlur()"
      />
      <small class="required-asterisk">*</small>
    </div>
    <div class="widget-input">
      <div class="required-flex">
        <input
          formControlName="link"
          class="button-input"
          type="text"
          placeholder="Paste the button link here …"
        />
        <small class="required-asterisk">*</small>
      </div>
      <norby-bin
        (remove)="removeButton()"
        [showBin]="true"
        [item]="'Widget'"
      ></norby-bin>
    </div>
  </div>
</form>
<norby-modal
  *ngIf="ss.savingWidget && !widgetButtonForm.valid && widgetButtonForm.touched"
  (closeModal)="closeModalWindow()"
  ><div>
    <h4 class="modal-title">Fill all required fields.</h4>
    <div class="modal-content">
      <p *ngIf="!widgetButtonForm.get('name')?.value">
        Field "Enter the button text…" is required.
      </p>
      <p *ngIf="!widgetButtonForm.get('link')?.value">
        Field "Paste the button link here …" is required.
      </p>
    </div>
    <button class="main-button" (click)="closeModalWindow()">Got it!</button>
  </div></norby-modal
>
