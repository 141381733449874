<div class="switcher-container">
  <div
    class="switch"
    [class.disabled]="!enabled"
    (click)="toggleSwitch()"
    [ngStyle]="{ order: switcherPosition === 'left' ? 1 : 2 }"
  >
    <ng-container *ngIf="enabled && switchedOn">
      <svg
        width="28"
        height="16"
        viewBox="0 0 28 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="28" height="16" rx="8" fill="var(--nrb-secondary-color)" />
        <circle cx="20" cy="8" r="7" fill="white" />
      </svg>
    </ng-container>
    <ng-container *ngIf="enabled && !switchedOn">
      <svg
        width="28"
        height="16"
        viewBox="0 0 28 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="28" height="16" rx="8" fill="var(--grayF1)" />
        <g filter="url(#filter0_d)">
          <circle cx="8" cy="8" r="7" fill="white" />
        </g>
        <defs>
          <filter
            id="filter0_d"
            x="0"
            y="0"
            width="22"
            height="22"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="1" dy="1" />
            <feGaussianBlur stdDeviation="2" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </ng-container>
    <ng-container *ngIf="!enabled && switchedOn">
      <svg
        width="28"
        height="16"
        viewBox="0 0 28 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="28" height="16" rx="8" fill="#F6AFBC" />
        <circle cx="20" cy="8" r="7" fill="white" />
      </svg>
    </ng-container>
    <ng-container *ngIf="!enabled && !switchedOn">
      <svg
        width="28"
        height="16"
        viewBox="0 0 28 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="28" height="16" rx="8" fill="var(--grayF1)" />
        <circle cx="8" cy="8" r="7" fill="white" />
      </svg>
    </ng-container>
  </div>
  <div
    class="switcher-label"
    *ngIf="label"
    [class.disabled]="!enabled"
    [ngStyle]="{ order: switcherPosition === 'left' ? 2 : 1 }"
  >
    {{ label }}
  </div>
</div>
