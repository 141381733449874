import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { SelectOption } from 'src/app/components/select/select.component';
import { Slug } from '../../domain/Slug';
import { Bot } from '../../types/Automations/Bot';
import { FreeLeftSlugs } from '../../types/Automations/FreeLeftSlugs';
import { SearchSlugRequest } from '../../types/Automations/SearchSlugRequest';
import { SlugCreationStep } from '../../types/Automations/SlugCreationSteps';
import { SlugFilterType } from '../../types/Automations/SlugFilterType';
import { SlugLiteralStatus } from '../../types/Automations/SlugLiteralStatus';
import { SlugBody, SlugDTO } from '../../types/Automations/SlugModel';
import { TriggerTypes } from '../../types/Automations/TriggerTypes';
import { SlugsApiService } from '../api/slugs-api.service';
import { MixpanelService } from '../mixpanel.service';
import { ProfileService } from '../profile.service';
import { SpinnerService } from '../spinner.service';
import { UserLanguagesService } from '../user-languages.service';
import { AnswersService } from './answers.service';
import { WidgetService } from './widget.service';

@Injectable({
  providedIn: 'root',
})
export class SlugsService {
  activePage = 2;
  disabledPage = 2;
  limit = 15;
  public selectedBotId$ = new BehaviorSubject<number>(0);
  public botOptions: SelectOption[] | [] = [];
  public filteredType$ = new BehaviorSubject<SlugFilterType>('all');
  public currentTab$ = new BehaviorSubject<SlugLiteralStatus>('Active');
  public error$ = new BehaviorSubject<Map<string, string>>(new Map());
  public subscriptionError$ = new BehaviorSubject<string>('');
  public slugCreationStep$ = new BehaviorSubject<SlugCreationStep>('closed');
  public nextRouteState: RouterStateSnapshot | undefined = undefined;

  public totalSlugs$ = new BehaviorSubject<Slug[]>([]);
  public enabledSlugs$ = new BehaviorSubject<Slug[]>([]);
  public disabledSlugs$ = new BehaviorSubject<Slug[]>([]);
  public selectedSlug$ = new BehaviorSubject<Slug | undefined>(undefined);
  public disabledManualSlugsCount$ = new BehaviorSubject<number>(0);
  public enabledManualSlugsCount$ = new BehaviorSubject<number>(0);
  public freeSlugsCount = new BehaviorSubject<FreeLeftSlugs>({
    free_manual_slugs: 0,
    free_automatic_slugs: 0,
  });

  public savingWidget = false;
  public newSlugAdding = false;
  public saving = false;

  // Spinners
  public spinnerSidebar$ = new BehaviorSubject<boolean>(false);
  public slugsLoader$ = new BehaviorSubject<boolean>(false);
  public hasUnsavedChanges$ = new BehaviorSubject<boolean>(false);
  public triggerTypes$ = new BehaviorSubject<TriggerTypes | null>(null);

  constructor(
    private ass: AnswersService,
    private sas: SlugsApiService,
    private spinnerService: SpinnerService,
    private uls: UserLanguagesService,
    private mx: MixpanelService,
    private profileService: ProfileService,
    private ws: WidgetService,
  ) {}

  public catchErrorMessages = (
    error: HttpErrorResponse,
  ): Map<string, string> =>
    error.status === 422
      ? new Map(Object.entries(error.error.errors))
      : new Map([['unknown.error', `${error.message}`]]);

  addSlug() {
    this.startNewSlugAdding();
    this.selectSlug(undefined);
    this.profileService.closeAutomationGuide();
    this.selectedSlug$.next(Slug.emptyAutomaticSlug());
    this.mx.track('click_add_automations', 'automations');
  }

  startNewSlugAdding() {
    this.newSlugAdding = true;
    this.hasUnsavedChanges$.next(false);
  }

  public selectSlug(slug: Slug | undefined): void {
    this.error$.next(new Map());

    const prevSlug = this.selectedSlug$.value;

    if (prevSlug) {
      prevSlug.setSelected(false);
    }

    if (this.newSlugAdding && slug) {
      const selectedSlug = this.selectedSlug$.value;
      const hasContentOrWidget = !!(
        selectedSlug?.content.content || selectedSlug?.widgets$.value[0]
      );

      if (hasContentOrWidget) {
        this.hasUnsavedChanges$.next(true);
        this.selectedSlug$.next(prevSlug);
      } else {
        this.newSlugAdding = false;
      }
    }

    if (slug) {
      slug.setSelected(true);
      if (!slug.isNew) {
        this.uls.setInitialLanguageValues();
      }
    }

    this.selectedSlug$.next(slug);
  }

  public getEnabledSlugs(id: number): Observable<Slug[]> {
    this.selectedBotId$.next(id);
    return this.sas.loadActiveSlugs(id).pipe(
      map((res) => res.map((dto: SlugDTO) => new Slug(dto))),
      tap((slugs) => {
        this.enabledSlugs$.next(slugs);
        this.enabledManualSlugsCount$.next(
          slugs.filter((s) => s.type === 'manual').length,
        );
        this.spinnerSidebar$.next(false);
      }),
    );
  }

  public getBotsList(): Observable<Bot[]> {
    return this.sas.getBotsList().pipe(
      tap((botList) => {
        if (botList && botList.length > 0) {
          this.botOptions = botList.map((bot) => ({
            key: bot.id,
            value: bot.name,
          }));
        }
      }),
    );
  }

  public getDisabledSlugs(id: number): Observable<Slug[]> {
    this.selectedBotId$.next(id);
    return this.sas.loadDisabledSlugs(id).pipe(
      map((res) => res.map((dto: SlugDTO) => new Slug(dto))),
      tap((slugs) => {
        this.disabledSlugs$.next(slugs);
        this.disabledManualSlugsCount$.next(
          slugs.filter((s) => s.type === 'manual').length,
        );
        this.spinnerSidebar$.next(false);
      }),
    );
  }
  public getFreeSlugsCount = () =>
    this.sas
      .getCountOfFreeSlugs()
      .pipe(map((free) => this.freeSlugsCount.next(free)));

  public createFullManualSlug(slug: Slug): void {
    this.trackSlugData(slug, 1);
    this.spinnerService.open();
    const slugBody = this.getManualSlugBody(slug);
    slug.updateAnswersState(
      this.ass.answersState$.value,
      this.uls.languageId$.value,
    );
    this.sas
      .createFullManualSlug(slugBody)
      .pipe(map((x) => new Slug(x)))
      .subscribe({
        next: (res) => {
          this.spinnerService.close();
          this.disabledSlugs$.next([res, ...this.disabledSlugs$.value]);
          this.error$.next(new Map());
          this.selectSlug(res);
          this.hasUnsavedChanges$.next(false);
        },
        error: (error) => {
          if (error) {
            this.error$.next(this.catchErrorMessages(error));
            this.spinnerService.close();
            this.hasUnsavedChanges$.next(false);
          }
        },
      });
  }

  public updateFullManualSlug(slug: Slug): void {
    this.newSlugAdding = false;
    this.saving = true;
    this.trackSlugData(slug, 0);
    this.spinnerService.open();
    this.savingWidget = true;
    const slugBody = this.getManualSlugBody(slug);
    slug.updateAnswersState(
      this.ass.answersState$.value,
      this.uls.languageId$.value,
    );
    this.sas
      .updateFullSlug(slugBody, slug.id)
      .pipe(map((x) => new Slug(x)))
      .subscribe({
        next: () => {
          slug.hasUnsavedChanges = false;
          this.spinnerService.close();
          this.error$.next(new Map());
          this.hasUnsavedChanges$.next(false);
          this.saving = false;
        },
        error: (error) => {
          if (error) {
            this.error$.next(this.catchErrorMessages(error));
            this.spinnerService.close();
            this.hasUnsavedChanges$.next(false);
          }
        },
      });
  }

  createFullAutoSlug(slug: Slug): void {
    this.trackSlugData(slug, 1);
    this.spinnerService.open();
    const slugBody = this.getAutoSlugBody(slug);
    this.newSlugAdding = false;
    this.hasUnsavedChanges$.next(false);
    this.saving = true;

    this.sas
      .createFullAutoSlug(slugBody)
      .pipe(
        map((x) => new Slug(x as SlugDTO)),
        tap((slug) => {
          this.spinnerService.close();
          this.enabledSlugs$.next([slug, ...this.enabledSlugs$.value]);
          this.error$.next(new Map());
          slug.active
            ? this.currentTab$.next('Active')
            : this.currentTab$.next('Disabled');
          slug.setUnsavedChanges(false);
          if (!this.selectedSlug$.value?.id && !this.nextRouteState) {
            this.selectSlug(slug);
          }
          this.saving = false;
        }),
        catchError((error) => {
          if (error) {
            this.error$.next(this.catchErrorMessages(error));
            this.spinnerService.close();
          }
          return of(null);
        }),
        switchMap((slug) => (slug ? this.getFreeSlugsCount() : of(null))),
      )
      .subscribe();
  }

  updateFullAutoSlug(slug: Slug): void {
    this.newSlugAdding = false;
    this.trackSlugData(slug, 0);
    this.spinnerService.open();
    this.savingWidget = true;
    this.saving = true;

    const slugBody = this.getAutoSlugBody(slug);
    this.sas
      .updateFullAutoSlug(slugBody, slug.id)
      .pipe(map((x) => new Slug(x)))
      .subscribe({
        next: () => {
          slug.hasUnsavedChanges = false;
          this.spinnerService.close();
          this.error$.next(new Map());
          this.hasUnsavedChanges$.next(false);
          this.saving = false;
        },
        error: (error) => {
          if (error) {
            this.error$.next(this.catchErrorMessages(error));
            this.spinnerService.close();
            this.hasUnsavedChanges$.next(false);
          }
        },
      });
  }

  public deleteSlug(id: number): void {
    this.spinnerService.open();
    this.sas
      .removeSlug(id)
      .pipe(switchMap(() => this.getFreeSlugsCount()))
      .subscribe({
        next: () => {
          this.disabledSlugs$.next(
            this.disabledSlugs$.value.filter((item) => item.id !== id),
          );
          this.enabledSlugs$.next(
            this.enabledSlugs$.value.filter((item) => item.id !== id),
          );
          this.selectedSlug$.next(undefined);
          this.spinnerService.close();
        },
        complete: () => {
          this.spinnerService.close();
        },
      });
  }

  public editSlugState(slug: Slug, status: boolean): void {
    const slugResponseBody = { id: slug.id, active: status };
    this.spinnerSidebar$.next(true);
    this.sas.updateSlugStatus(slugResponseBody).subscribe({
      next: () => {
        slug.updateActiveState(status);
        this.spinnerSidebar$.next(false);
        slug.active ? this.addToActive(slug) : this.addToDisabled(slug);
      },
      error: (error: { error: { errors: [] } }): void => {
        this.subscriptionError$.next(Object.values(error.error.errors)[0][0]);
        if (error) {
          slug.updateActiveState(true);
        }
      },
    });
  }

  private addToActive(slug: Slug): void {
    this.enabledSlugs$.next([slug, ...this.enabledSlugs$.value]);
    this.currentTab$.next('Active');
    this.disabledSlugs$.next(
      this.disabledSlugs$.value.filter((s) => s.id !== slug.id),
    );
  }

  private addToDisabled(slug: Slug): void {
    this.disabledSlugs$.next([slug, ...this.disabledSlugs$.value]);
    this.currentTab$.next('Disabled');
    this.enabledSlugs$.next(
      this.enabledSlugs$.value.filter((s) => s.id !== slug.id),
    );
  }

  protected getManualSlugBody(slugBody: Slug): object {
    const { slug, questions, widgets$, active, trigger$ } = slugBody;
    return {
      slug: {
        bot_id: this.selectedBotId$.value,
        active: active,
        slug,
        trigger: trigger$.value,
      },
      questions: Object.values(questions.value).flat(),
      answers: this.ass.answersState$.value
        ? Object.values(this.ass.answersState$.value).flat()
        : [],
      widgets: this.ws.convertWidgetsToString(widgets$.value),
    };
  }

  protected getAutoSlugBody(slugBody: Slug): SlugBody {
    const { slug, content, widgets$ } = slugBody;
    return {
      slug: { bot_id: this.selectedBotId$.value, slug },
      content,
      widgets: this.ws.convertWidgetsToString(widgets$.value),
    };
  }

  setNextPage(nextState: RouterStateSnapshot | undefined): void {
    if (nextState) {
      this.nextRouteState = nextState;
    }
  }

  getPaginatedSlugs(slugStatus: SlugLiteralStatus): void {
    this.slugsLoader$.next(true);
    slugStatus === 'Active'
      ? this.sas
          .loadActiveSlugs(
            this.selectedBotId$.value,
            this.activePage++,
            this.limit,
          )
          .pipe(
            tap((data) => {
              const enabledSlugs = [
                ...this.filterSlugsById(
                  this.enabledSlugs$.value,
                  this.convertToEnabledSlugs(data),
                ),
              ];
              this.enabledSlugs$.next(enabledSlugs);
            }),
          )
          .subscribe({
            complete: () => this.slugsLoader$.next(false),
          })
      : this.sas
          .loadDisabledSlugs(
            this.selectedBotId$.value,
            this.disabledPage++,
            this.limit,
          )
          .pipe(
            tap((data) => {
              const disabledSlugs = [
                ...this.filterSlugsById(
                  this.disabledSlugs$.value,
                  this.convertToDisabledSlugs(data),
                ),
              ];
              this.disabledSlugs$.next(disabledSlugs);
            }),
          )
          .subscribe({
            complete: () => this.slugsLoader$.next(false),
          });
  }

  public loadFilteredSlugs(slugRequestData: SearchSlugRequest): void {
    this.filteredType$.next(slugRequestData.type);
    this.slugsLoader$.next(true);
    this.sas
      .filterSlugs({
        botId: this.selectedBotId$.value,
        type: slugRequestData.type,
        slug: null,
      })
      .pipe(map((resDto) => resDto.map((dto: SlugDTO) => new Slug(dto))))
      .subscribe({
        next: (res) => {
          this.disabledSlugs$.next(res.filter((slug) => !slug.active));
          this.enabledSlugs$.next(res.filter((slug) => slug.active));
        },
        error: (error) => {
          this.slugsLoader$.next(false);
          console.log(error, 'Can not filter a slug');
        },
        complete: () => {
          this.slugsLoader$.next(false);
        },
      });
  }

  public getSearchedSlug(slugRequestData: SearchSlugRequest): void {
    this.filteredType$.next(slugRequestData.type);
    this.slugsLoader$.next(true);
    this.sas
      .findSlug({
        botId: this.selectedBotId$.value,
        type: slugRequestData.type,
        slug: slugRequestData.slug,
      })
      .pipe(map((resDto) => resDto.map((dto: SlugDTO) => new Slug(dto))))
      .subscribe({
        next: (res) => {
          this.disabledSlugs$.next(res.filter((s) => !s.active));
          this.enabledSlugs$.next(res.filter((s) => s.active));
        },
        error: (error) => {
          this.slugsLoader$.next(false);
          console.log(error, 'Can not search a slug');
        },
        complete: () => {
          this.slugsLoader$.next(false);
        },
      });
  }

  public resetCounter() {
    this.activePage = 2;
    this.disabledPage = 2;
  }

  public convertToEnabledSlugs(data: SlugDTO[]): Slug[] {
    return data
      .filter((dto) => dto.active === 1)
      .map((slugDto) => new Slug(slugDto));
  }

  public convertToDisabledSlugs(data: SlugDTO[]): Slug[] {
    return data
      .filter((dto) => dto.active === 0)
      .map((slugDto) => new Slug(slugDto));
  }

  private filterSlugsById(disValues1: Slug[], disValues2: Slug[]): Slug[] {
    const combinedArrays = [...disValues1, ...disValues2];
    const filteredArray: Slug[] = [];
    combinedArrays.filter((item) => {
      if (!filteredArray.some((element) => element.id === item.id)) {
        filteredArray.push(item);
      }
    });
    return filteredArray;
  }
  public getTriggerTypes(): void {
    this.sas.getTriggerTypes(this.selectedBotId$.value).subscribe((res) => {
      this.triggerTypes$.next(res);
    });
  }

  trackSlugData(slug: Slug, action: number): void {
    this.mx.trackForSaveSlug('click_save_automation', 'automations', [
      { key: 'answers_amount', value: slug.getAllAnswersCount() },
      { key: 'questions_amount', value: slug.questionsCount.value },
      { key: 'widget', value: this.ws.getWidgetType(slug) },
      { key: 'type', value: slug.type },
      { key: 'new_automation', value: action },
    ]);
  }
}
