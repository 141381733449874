import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Slug } from '../../domain/Slug';
import { Widget, WidgetType } from '../../types/Automations/Widgets/Widget';
import { Locale } from '../../domain/Locale';

@Injectable({
  providedIn: 'root',
})
export class WidgetService {
  public currentWidgetType$ = new BehaviorSubject<string>('');

  public convertWidgetsToString(widgets: Partial<Record<Locale, Widget>>[]) {
    return widgets.map((w) => ({
      ...Object.values(w)[0],
      widget: JSON.stringify(Object.values(w)[0].widget),
    }));
  }

  public getWidgetType(slug: Slug): WidgetType | 'none' {
    const widgets$ = slug.widgets$.value;

    if (widgets$.length > 0) {
      const widget = widgets$.find((w) => Object.values(w)[0]?.type);

      if (widget) {
        const widgetType = Object.values(widget)[0]?.type;
        if (widgetType) {
          return widgetType;
        }
      }
    }

    return 'none';
  }

  reset(): void {
    if (this.currentWidgetType$.value) {
      this.currentWidgetType$.next('');
    }
  }
}
