<norby-slug-wrapper
  [title]="'Content'"
  [subtitle]="
    'Provide content for the bot (e.g., a knowledge base article). No need to add it in multiple languages, as the bot translates it in chats on the go.'
  "
>
  <form
    *ngIf="ss.selectedSlug$ | async as selectedSlug"
    class="slug-content-form"
    [formGroup]="autoSlugContent"
  >
    <div class="editor-container">
      <quill-editor
        formControlName="content"
        placeholder="Example of your content:

OrangeHarvest Foods is a pioneering force in the sustainable food industry, committed to transforming how we grow, consume, and think about our food. Established in 2024, OrangeHarvest Foods specializes in producing and distributing high-quality, organic food products that support health, community, and the environment.

Our approach is grounded in the principles of sustainability and ethical farming practices. We partner with local farmers and producers who share our vision for a healthier planet, ensuring that all OrangeHarvest products are not only organic but also sustainably sourced. Our range includes everything from farm-fresh produce to artisanal breads, all cultivated without the use of harmful pesticides or genetically modified organisms (GMOs).

..."
        (onContentChanged)="
          ss.selectedSlug$.value?.type === 'automatic' &&
            getContentValue(selectedSlug)
        "
        [sanitize]="true"
        [modules]="quillModules"
        maxlength="4000"
        #quill
      >
      </quill-editor>
      <div class="remove-button">
        <norby-bin
          (remove)="removeContent()"
          [showBin]="true"
          [item]="'Content'"
          [disabled]="!content"
        ></norby-bin>
      </div>
    </div>
    <div class="textarea-error">
      <div *ngIf="(ss.error$ | async)?.has('content.content')">
        {{ (ss.error$ | async)?.get("content.content") }}
      </div>
    </div>
    <div
      *ngIf="content && !autoSlugContent.get('content')?.errors?.maxlength"
      class="char-counter"
    >
      Content length: {{ content.length }}/4000
    </div>
    <div
      *ngIf="!content && !autoSlugContent.get('content')?.errors?.maxlength"
      class="char-counter"
    >
      Content length: 0/4000
    </div>
    <div
      *ngIf="autoSlugContent.get('content')?.errors?.maxlength"
      class="char-counter error"
    >
      <div class="char-error-text">
        The text is too long. Max value is 4000 symbols.
      </div>
      <div *ngIf="content">Content length: {{ content.length }}/4000</div>
      <div *ngIf="!content">Content length: 0/4000</div>
    </div>
  </form>
  <norby-modal
    *ngIf="
      autoSlugContent.get('content')?.errors?.maxlength &&
      isOpenModal &&
      !removingExtraSymbols
    "
    (closeModal)="closeModal($event)"
  >
    <h4 class="modal-title">The text is too long.</h4>
    <p class="modal-content">
      Max value is 4000 symbols. Your text have {{ content.length }}.
    </p>
    <button class="main-button" (click)="closeModal($event)">Got it!</button>
  </norby-modal>
</norby-slug-wrapper>
