import { Component, HostListener, Input, OnChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import Chat from '../../@core/domain/Chat';
import { NorbyDateFormat } from '../../@core/domain/NorbyDateFormat';
import { Profile } from '../../@core/domain/Profile';
import { AttributesStateService } from '../../@core/services/attributes-state.service';
import { Attribute } from '../../@core/types/Attribute';

export type ShortAttribute = {
  name: string;
  value: string | number | boolean | undefined;
  type: string;
};

@Component({
  selector: 'norby-chat-attributes',
  templateUrl: './chat-attributes.component.html',
  styleUrls: ['./chat-attributes.component.css'],
})
export class ChatAttributesComponent implements OnChanges {
  chatAttributes: Observable<ShortAttribute[]>;
  userAttributes: Observable<ShortAttribute[]>;
  isOpen = true;

  @HostListener('window:resize') onResize() {
    window.innerWidth <= 1180 ? (this.isOpen = false) : (this.isOpen = true);
  }

  @Input() profile: Profile | undefined;
  @Input() chat: Chat | undefined;
  @Input() email: string | null = null;
  @Input() name: string | null = null;

  constructor(private as: AttributesStateService) {
    this.chatAttributes = this.as.chatAttributes$.pipe(
      map((attribute) =>
        attribute.map((a: Attribute) => this.mapToShortAttribute(a)),
      ),
    );
    this.userAttributes = this.as.userAttributes$.pipe(
      map((attribute) =>
        attribute.map((a: Attribute) => this.mapToShortAttribute(a)),
      ),
    );
  }

  ngOnChanges(): void {
    if (this.chat) {
      this.as.loadAttributes(this.chat.uid, this.chat.participant.uid);
    }
    window.innerWidth <= 1180 ? (this.isOpen = false) : (this.isOpen = true);
  }

  setOpen(newState: boolean): void {
    this.isOpen = newState;
  }

  mapToShortAttribute(attribute: Attribute): ShortAttribute {
    const { name, value, type } = attribute;
    if (attribute.data_type === 'date' && !!attribute.value) {
      const date = new Date(String(value));
      if (date) {
        const formatter = new NorbyDateFormat(date);
        return { name, type, value: formatter.norbyDateFormat() };
      }
    }

    if (typeof value === 'string' && value.includes('T') && !!attribute.value) {
      return {
        name,
        type,
        value:
          value && value.includes('T')
            ? value
                .split(', ')
                .map((dateStr) => {
                  const date = new Date(dateStr.trim());
                  return !isNaN(date.getTime())
                    ? date
                        .toLocaleString('en-US', {
                          weekday: 'long',
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: false,
                          timeZoneName: 'short',
                        })
                        .replace(',', '')
                    : dateStr;
                })
                .join(', ')
            : new NorbyDateFormat(new Date(String(value))).norbyDateFormat(),
      };
    }
    return { name, type, value };
  }
}
