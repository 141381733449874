import {
  AfterViewInit,
  Component,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { QuillEditorComponent } from 'ngx-quill';
import { Subscription } from 'rxjs';
import { modules } from '../../../../pages/automations-page/slug-window/modules';
import { Slug } from './../../../../@core/domain/Slug';
import { SpinnerService } from '../../../../@core/services/spinner.service';
import { SlugsService } from '../../../../@core/services/automations/slugs.service';

@Component({
  selector: 'norby-slug-textarea',
  templateUrl: './slug-textarea.component.html',
  styleUrls: ['./slug-textarea.component.css'],
})
export class SlugTextareaComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  @Input() selectedSlug: Slug | null | undefined = undefined;
  @ViewChild('quill') quill: QuillEditorComponent | undefined;

  content = '';
  autoSlugContent: FormGroup;
  isOpenModal = false;
  removingExtraSymbols = false;
  subscriptions: Subscription[] = [];
  readonly quillModules = modules;

  constructor(
    private fb: FormBuilder,
    protected ss: SlugsService,
    private spinnerService: SpinnerService,
  ) {
    this.autoSlugContent = this.fb.group({
      content: [
        ss.selectedSlug$.value?.content.content ?? '',
        [Validators.required, Validators.maxLength(4000)],
      ],
    });
  }

  @HostListener('document:keyup', ['$event']) keyEvent(
    event: KeyboardEvent,
  ): void {
    if (event.code === 'Backspace' && this.isOpenModal) {
      this.removingExtraSymbols = true;
      this.isOpenModal = false;
    }

    if (event.code !== 'Backspace' && this.content.length > 4000) {
      this.removingExtraSymbols = false;
    }

    if (event.code === 'Backspace' && !this.content) {
      this.removeContent();
    }
  }

  ngOnChanges() {
    this.quill && this.quill.quillEditor.focus();
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.ss.selectedSlug$.subscribe((slug) => {
        this.autoSlugContent.controls['content'].setValue(
          slug?.content?.content,
        );
        if (slug) {
          this.content = slug?.content?.content;
          if (this.content) {
            this.setFocusAfterContent();
          }
        }
        this.openModal();
      }),
    );
  }

  ngAfterViewInit() {
    this.setFocusOnQuill();
  }

  setFocusOnQuill(): void {
    if (this.quill) {
      this.subscriptions.push(
        this.quill.onEditorCreated.subscribe(() => {
          this.setFocusAfterContent();
        }),
      );
    }
  }

  openModal(): void {
    this.isOpenModal = true;
  }

  closeModal(event: Event): void {
    this.isOpenModal = false;

    if (event) {
      this.removingExtraSymbols = true;
    }
  }

  setFocusAfterContent(): void {
    const editor = this.quill?.quillEditor;
    if (editor) {
      editor.focus();
      const length = editor.getLength();
      editor.setSelection(length, length);
    }
  }

  getContentValue(slug: Slug): void {
    if (this.selectedSlug && this.ss.selectedSlug$.value?.type !== 'manual') {
      this.selectedSlug.setUnsavedChanges(true);
    }

    const { content } = this.autoSlugContent.value;
    this.content = content;

    if (this.autoSlugContent.valid) {
      this.ss.selectedSlug$.subscribe((slug) => {
        if (
          slug &&
          ((this.content && this.content === slug.content.content) ||
            !slug.content ||
            !this.content)
        ) {
          slug.setUnsavedChanges(false);
        }
      });
      slug.setContent(content);
      this.spinnerService.close();
    } else {
      this.openModal();
    }
  }

  removeContent(): void {
    if (this.selectedSlug) {
      this.autoSlugContent.reset();
      this.content = '';
      this.selectedSlug.setContent('');
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
