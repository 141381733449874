<form
  [formGroup]="predefinedAnswersForm"
  (change)="savePredefinedAnswers()"
  class="predefined-box"
>
  <ng-container formArrayName="messages">
    <div
      *ngFor="let answer of formArrayAnswersInformation.controls; let i = index"
    >
      <div class="input-inner" [formGroupName]="i">
        <div class="required-flex">
          <input
            #answerInput
            formControlName="answer"
            class="button-input"
            type="text"
            placeholder="Enter a predefined answer …"
          />
          <small class="required-asterisk">*</small>
        </div>
        <div class="widget-input">
          <input
            formControlName="link"
            class="button-input"
            type="text"
            placeholder="Paste a link …"
          />
          <norby-bin
            (remove)="removePredefinedAnswer(i)"
            [showBin]="true"
            [item]="'Answer'"
          ></norby-bin>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="widget-input">
    <button
      (click)="addAnswerInput()"
      [style]="{ width: '100%' }"
      class="secondary-button"
      type="button"
      [disabled]="!predefinedAnswersForm.valid"
    >
      Add
    </button>
    <norby-remove-all-bin
      *ngIf="formArrayAnswersInformation.controls.length > 1"
      (remove)="removeAll()"
      [showBin]="true"
      [items]="'Answers'"
    ></norby-remove-all-bin>
  </div>
</form>
<norby-modal
  *ngIf="ss.savingWidget && !predefinedAnswersForm.valid"
  (closeModal)="closeModalWindow()"
  ><div>
    <h4 class="modal-title">Fill all required fields.</h4>
    <div class="modal-content">
      <p *ngIf="!predefinedAnswersForm.get('answer')?.value">
        Field "Enter a predefined answer …" is required.
      </p>
    </div>
    <button class="main-button" (click)="closeModalWindow()">Got it!</button>
  </div></norby-modal
>
