import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Slug } from '../../../../../@core/domain/Slug';
import { WidgetService } from '../../../../../@core/services/automations/widget.service';
import { Widget } from '../../../../../@core/types/Automations/Widgets/Widget';
import { UserLanguagesService } from '../../../../../@core/services/user-languages.service';
import { WidgetTypes } from '../../../../../@core/types/Automations/Widgets/WidgetTypes';
import { FormWidgetBaseComponent } from '../form-widget/form-widget-base.component';
import { AnswersWidgetComponent } from '../answers-widget/answers-widget.component';
import { WidgetComponentsRef } from '../types';
import { getIdByLocale, Locale } from '../../../../../@core/domain/Locale';
import { Subscription } from 'rxjs';
import { NewChatButtonWidgetComponent } from '../new-chat-button-widget/new-chat-button-widget.component';
import { MessageWidgetComponent } from '../message-widget/message-widget.component';
import { ProductWidgetComponent } from '../product-widget/product-widget.component';
import { ButtonWidgetComponent } from '../button-widget/button-widget.component';
import { SlugsService } from '../../../../../@core/services/automations/slugs.service';
import { ChatSearchStateService } from '../../../../../@core/services/chat-search-state.service';

@Component({
  selector: 'norby-automation-widgets',
  templateUrl: './automation-widgets.component.html',
  styleUrls: ['./automation-widgets.component.css'],
})
export class AutomationWidgetsComponent
  implements OnInit, OnChanges, OnDestroy
{
  public currentWidgetsObservable$ = this.ws.currentWidgetsObservable$;
  public isSelectVisible = false;
  public widgetOptions$ = this.ws.widgetOptions$;
  private uniqueId = 0;
  private widgetsData: Widget[] = [];
  private selectedSlugData: Slug | null = null;
  private subscription: Subscription = new Subscription();

  @ViewChild('widgetContainer', { read: ViewContainerRef, static: true })
  widgetContainer!: ViewContainerRef;
  private componentRefs: { [key: string]: ComponentRef<WidgetComponentsRef> } =
    {};

  constructor(
    private ss: SlugsService,
    protected ws: WidgetService,
    private ls: UserLanguagesService,
    private cdr: ChangeDetectorRef,
    protected as: ChatSearchStateService,
  ) {}

  ngOnInit() {
    this.ss.selectedSlug$.subscribe((selectedSlug) => {
      if (selectedSlug) {
        this.selectedSlugData = selectedSlug;
        this.subscription.add(
          this.ls.currentLanguage$.subscribe((locale) => {
            this.loadWidgetsForCurrentLanguage(locale);
          }),
        );
      }
    });
    this.as.loadAttributes();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedSlug && this.selectedSlugData) {
      if (this.selectedSlugData) {
        this.subscription.add(
          this.ls.currentLanguage$.subscribe((locale) => {
            this.loadWidgetsForCurrentLanguage(locale);
          }),
        );
        this.cdr.detectChanges();
      }
    }
  }

  private loadWidgetsForCurrentLanguage(locale: Locale): void {
    this.subscription.add(
      this.selectedSlugData!.widgets$.subscribe((widgets) => {
        this.clearWidgetContainer();
        this.cdr.detectChanges();

        widgets.forEach((widgetData) => {
          const localizedWidget = widgetData[locale];
          if (localizedWidget) {
            const componentType = this.getWidgetComponentType(
              localizedWidget.type,
            );
            if (componentType) {
              this.createComponent(componentType, localizedWidget);
            }
          }
        });

        this.widgetsData = widgets
          .map((widget) => widget[locale])
          .filter(Boolean) as Widget[];
        this.cdr.detectChanges();
      }),
    );
  }

  get widgetCount(): number {
    return this.widgetContainer ? this.widgetContainer.length : 0;
  }

  get isSelectEnabled(): boolean {
    return this.isSelectVisible || this.widgetsData.length < 1;
  }

  addWidgetForm() {
    this.isSelectVisible = true;
  }

  private createWidgetByType(
    widgetComponent: Type<WidgetComponentsRef>,
    type: WidgetTypes,
  ): void {
    this.createComponent(widgetComponent, {
      language_id: getIdByLocale(this.ls.currentLanguage$.value),
      slug_id: 0,
      tempId: this.generateUniqueId(),
      type: type,
      widget: undefined,
    });
  }

  public selectWidgetType({ key }: { key: WidgetTypes }): void {
    const widgetType =
      WidgetTypes[key.toUpperCase() as keyof typeof WidgetTypes];
    switch (widgetType) {
      case WidgetTypes.FORM:
        this.createWidgetByType(FormWidgetBaseComponent, WidgetTypes.FORM);
        break;
      case WidgetTypes.PREDEFINED_ANSWERS:
        this.createWidgetByType(
          AnswersWidgetComponent,
          WidgetTypes.PREDEFINED_ANSWERS,
        );
        break;
      case WidgetTypes.NEW_CHAT_BUTTON:
        this.createWidgetByType(
          NewChatButtonWidgetComponent,
          WidgetTypes.NEW_CHAT_BUTTON,
        );
        break;
      case WidgetTypes.MESSAGE:
        this.createWidgetByType(MessageWidgetComponent, WidgetTypes.MESSAGE);
        break;
      case WidgetTypes.PRODUCT_LIST:
        this.createWidgetByType(
          ProductWidgetComponent,
          WidgetTypes.PRODUCT_LIST,
        );
        break;
      case WidgetTypes.BUTTON:
        this.createWidgetByType(ButtonWidgetComponent, WidgetTypes.BUTTON);
        break;
      default:
        console.warn(`Unknown component type selected: ${widgetType}`);
    }
    this.ws.setCurrentWidgetType(widgetType);
    this.isSelectVisible = false;
  }

  private createComponent(
    component: Type<WidgetComponentsRef>,
    widgetData: Widget,
  ): void {
    if (!this.widgetContainer) return;

    const componentRef = this.widgetContainer.createComponent(component);
    this.addNewWidgetsData(widgetData);
    const isWidgetId = widgetData.id ? widgetData.id : widgetData.tempId;
    this.componentRefs[isWidgetId] = componentRef;

    componentRef.instance.widgetDetails = widgetData;

    componentRef.instance.update.subscribe((updatedData) => {
      this.handleSave(widgetData.id, updatedData);
    });

    componentRef.instance.save.subscribe((updatedData) => {
      this.handleSave(widgetData.tempId, updatedData);
    });
    componentRef.instance.remove.subscribe((w: Widget) => {
      const isWidgetId = w.id ? w.id : w.tempId;
      this.removeWidget(isWidgetId);
    });
  }

  private getWidgetComponentType(
    type: WidgetTypes,
  ): Type<WidgetComponentsRef> | null {
    switch (type) {
      case WidgetTypes.FORM:
        return FormWidgetBaseComponent;
      case WidgetTypes.PREDEFINED_ANSWERS:
        return AnswersWidgetComponent;
      case WidgetTypes.NEW_CHAT_BUTTON:
        return NewChatButtonWidgetComponent;
      case WidgetTypes.MESSAGE:
        return MessageWidgetComponent;
      case WidgetTypes.PRODUCT_LIST:
        return ProductWidgetComponent;
      case WidgetTypes.BUTTON:
        return ButtonWidgetComponent;
      default:
        console.warn(`Unknown component type: ${type}`);
        return null;
    }
  }

  private handleSave(tempId: string | number | undefined, data: Widget): void {
    let existingIndex;
    if (typeof tempId === 'number') {
      existingIndex = this.widgetsData.findIndex((item) => item.id === tempId);
    } else {
      existingIndex = this.widgetsData.findIndex(
        (item) => item.tempId === tempId,
      );
    }

    if (existingIndex !== -1) {
      this.widgetsData[existingIndex].widget = data;
      this.widgetsData[existingIndex].tempId = (data.id?.toString() ??
        tempId) as string;
    } else {
      this.widgetsData.push(data);
    }

    this.ws.setWidgetToObservable(this.widgetsData);
  }

  private addNewWidgetsData(widgetData: Widget): void {
    this.widgetsData.push(widgetData);
    this.ws.setWidgetToObservable(this.widgetsData);
  }

  private removeWidget(id: string | number): void {
    this.widgetsData = this.widgetsData.filter(
      (widget) => widget.id !== id && widget.tempId !== id,
    );

    const componentRef = this.componentRefs[id];
    if (componentRef) {
      componentRef.destroy();
      delete this.componentRefs[id];
    }

    this.ws.setWidgetToObservable(this.widgetsData);
  }

  removeAllWidgets() {
    this.clearWidgetContainer();
    this.ws.resetCurrentWidgets();
    this.isSelectVisible = true;
  }

  private clearWidgetContainer() {
    Object.values(this.componentRefs).forEach((ref) => ref.destroy());
    this.componentRefs = {};
    this.widgetContainer.clear();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private generateUniqueId(): string {
    return `widget-${this.uniqueId++}`;
  }
}
