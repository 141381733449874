import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ChangeDetectorRef,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FlatpickrOptions } from 'ngx-flatpickr-wrapper/lib/flatpickr-options.interface';

export interface FormWidgetCalendarData {
  label: string;
  isRange: boolean;
  isTime: boolean;
  isMultiply: boolean;
  isMandatory: boolean;
  attribute: string;
}

@Component({
  selector: 'norby-form-widget-calendar',
  templateUrl: './form-widget-calendar.component.html',
  styleUrls: ['./form-widget-calendar.component.css'],
})
export class FormWidgetCalendarComponent implements OnInit, OnDestroy {
  @Output() save = new EventEmitter<FormWidgetCalendarData>();
  @Output() remove = new EventEmitter();
  @Output() update = new EventEmitter();
  @Input() data: FormWidgetCalendarData | null = null;

  public isMandatory = true;
  public isRange = false;
  public isTime = false;
  public isMultiply = false;
  public attributeValue: string = '';
  public showCalendar = true; // Флаг для пересоздания `ngx-flatpickr-wrapper`

  public calendarWidgetFormLabel = new FormControl<string | null>('', {
    validators: [Validators.required],
    nonNullable: false,
  });

  private subscription: Subscription = new Subscription();

  datePickerConfig: FlatpickrOptions = {
    enableTime: false,
    dateFormat: 'Y-m-d H:i',
    mode: 'single',
  };

  constructor(private cdr: ChangeDetectorRef) {
    this.subscription.add(
      this.calendarWidgetFormLabel.valueChanges.subscribe(() => {
        this.emitCalendarData();
      }),
    );
  }

  ngOnInit(): void {
    if (this.data) {
      this.calendarWidgetFormLabel.setValue(this.data.label, {
        emitEvent: false,
      });
      this.isMandatory = this.data.isMandatory;
      this.isRange = this.data.isRange;
      this.isTime = this.data.isTime;
      this.isMultiply = this.data.isMultiply;
      this.attributeValue = this.data.attribute;
      this.updateDatePickerConfig();
    }
  }

  private emitCalendarData(): void {
    this.save.emit({
      label: this.calendarWidgetFormLabel.value || '',
      isRange: this.isRange,
      isMandatory: this.isMandatory,
      isTime: this.isTime,
      isMultiply: this.isMultiply,
      attribute: this.attributeValue,
    });
  }

  toggleDateRange(): void {
    this.isRange = !this.isRange;
    if (this.isRange) this.isMultiply = false;
    this.updateDatePickerConfig();
    this.emitCalendarData();
  }

  toggleTimeRange(): void {
    this.isTime = !this.isTime;
    this.updateDatePickerConfig();
    this.emitCalendarData();
  }

  toggleMultiplyRange(): void {
    this.isMultiply = !this.isMultiply;
    if (this.isMultiply) this.isRange = false;
    this.updateDatePickerConfig();
    this.emitCalendarData();
  }

  updateDatePickerConfig(): void {
    this.datePickerConfig = {
      enableTime: this.isTime,
      dateFormat: this.isTime ? 'Y-m-d H:i' : 'Y-m-d',
      mode: this.isMultiply ? 'multiple' : this.isRange ? 'range' : 'single',
    };

    this.showCalendar = false;
    setTimeout(() => {
      this.showCalendar = true;
      this.cdr.detectChanges();
    }, 10);
  }

  onSelectChange(selectedValue: string): void {
    this.attributeValue = selectedValue;
    this.emitCalendarData();
  }

  toggleForm(): void {
    this.isMandatory = !this.isMandatory;
    this.emitCalendarData();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
