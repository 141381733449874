import {
  AfterContentChecked,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Profile } from 'src/app/@core/domain/Profile';
import Chat from '../../@core/domain/Chat';
import { TitleNotificationService } from '../../@core/services/title-notification.service';

@Component({
  selector: 'norby-message-form',
  templateUrl: './message-form.component.html',
  styleUrls: ['./message-form.component.css'],
})
export class MessageFormComponent implements AfterContentChecked {
  @ViewChild('textarea') textAreaInput:
    | ElementRef<HTMLTextAreaElement>
    | undefined;
  @Input() selectedChat: Chat | undefined;
  @Input() minHeight = 34;
  @Input() profile: Profile | undefined;
  @Input() isOnline: boolean | null = false;
  @Input() email: string | null = null;
  @Output() newMessage = new EventEmitter<string>();
  @Output() newEmail = new EventEmitter<string>();
  @Output() newImage = new EventEmitter<File>();
  @Output() newEmailImage = new EventEmitter<File>();

  private lastKeyWasShift = false;
  private lastKeyPressTime = 0;
  private shiftEnterTimeLimit = 200;
  messageForm: UntypedFormGroup = this.fb.group({ message: [''] });
  placeholder = '';

  constructor(
    private fb: UntypedFormBuilder,
    public ts: TitleNotificationService,
  ) {}

  public get showEmail(): boolean {
    return this.isOnline === false && this.email !== '';
  }

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    const now = Date.now();
    if (event.code === 'Enter') {
      const message = this.messageForm.value.message || '';

      if (
        this.lastKeyWasShift &&
        now - this.lastKeyPressTime < this.shiftEnterTimeLimit
      ) {
        this.insertNewLine();
        this.resetKeyPressTracking();
        return;
      } else if (!event.shiftKey) {
        const trimmedMessage = message.trim();
        event.preventDefault();

        if (!trimmedMessage && message.replace(/\n/g, '').trim() === '') {
          this.messageForm.patchValue({ message: '' });
          this.resetTextareaHeight();
          return;
        }

        if (trimmedMessage) {
          if (this.showEmail) {
            this.newEmail.emit(trimmedMessage);
          } else {
            this.newMessage.emit(trimmedMessage);
          }
          this.messageForm.patchValue({ message: '' }); // Reset to empty string
          this.resetTextareaHeight();
        }
      }
    }

    if (event.code === 'ShiftLeft' || event.code === 'ShiftRight') {
      this.lastKeyWasShift = true;
      this.lastKeyPressTime = now;
    }
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    const selectionEl = document.querySelector('.chat-messages');

    if (
      !this.textAreaInput ||
      this.textAreaInput.nativeElement.contains(target)
    ) {
      return;
    }

    if (
      target.tagName.toLowerCase() === 'input' ||
      target.tagName.toLowerCase() === 'button' ||
      target.tagName.toLowerCase() === 'img' ||
      target.className === 'root' ||
      target.className === 'scrollButton scrollButtonRight' ||
      target.className === 'scrollButton scrollButtonLeft' ||
      target.className === ''
    ) {
      this.textAreaInput.nativeElement.blur();
    } else if (
      selectionEl &&
      selectionEl.contains(target) &&
      window.getSelection()?.toString()
    ) {
      this.textAreaInput.nativeElement.blur();
    } else {
      this.textAreaInput.nativeElement.focus();
    }
  }

  private resetKeyPressTracking(): void {
    this.lastKeyWasShift = false;
    this.lastKeyPressTime = 0;
  }

  private insertNewLine(): void {
    const currentText = this.messageForm.value.message || '';
    this.messageForm.patchValue({ message: currentText });
    this.textAreaInput?.nativeElement.focus();
    this.resetTextareaHeight();
  }

  ngAfterContentChecked(): void {
    this.placeholder = this.showEmail ? 'Email text ...' : 'Respond here ...';
  }

  resetTextareaHeight(): void {
    if (this.textAreaInput) {
      this.textAreaInput.nativeElement.style.height = this.minHeight + 'px';
    }
  }

  send(): void {
    const message = this.messageForm.value.message?.trim() || '';
    if (!message) {
      return;
    }
    this.newMessage.emit(message);
    this.messageForm.patchValue({ message: '' }); // Reset to empty string instead of null
    this.resetTextareaHeight();
  }

  sendEmail(): void {
    const message = this.messageForm.value.message?.trim() || '';
    if (!message) {
      return;
    }
    this.newEmail.emit(message);
    this.messageForm.patchValue({ message: '' }); // Reset to empty string instead of null
    this.messageForm.reset();
    this.resetTextareaHeight();
  }

  sendImage($event: Event): void {
    const file = this.getFileFromEvent($event);
    if (!file) {
      return;
    }

    if (this.showEmail) {
      this.newEmailImage.emit(file);
    } else {
      this.newImage.emit(file);
    }
  }

  sendMessage(): void {
    if (this.email) {
      this.send();
    } else {
      this.sendEmail();
    }
  }

  private getFileFromEvent($event: Event): File | null {
    const target = $event.target as HTMLInputElement;
    if (!target.files || target.files.length < 1) {
      return null;
    }
    return target.files[0];
  }
}
